import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../components/common/Store";

type StepProgressState = {
  backClickCount: number,
  nextClickCount: number
}

const initialState: StepProgressState = {
  backClickCount: 0,
  nextClickCount: 0
};

const stepProgressSlice = createSlice({
  name: "stepProgress",
  initialState,
  reducers: {
    setAddBackClickCount(state) {
      state.backClickCount = state.backClickCount + 1;
    },
    setAddNextClickCount(state) {
      state.nextClickCount = state.nextClickCount + 1;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const {
  setAddBackClickCount,
  setAddNextClickCount,
  reset,
} = stepProgressSlice.actions;

export const getStepProgressState = (state: RootState) => state.stepProgress;

export default stepProgressSlice.reducer;
