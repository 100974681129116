import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { UsersDto } from "./api/dto/UserDto";

type UserState = {
  users: UsersDto[]
}

const initialState: UserState = {
  users: []
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
});

export const {
  reset,
} = userSlice.actions;

export default userSlice.reducer;
