import { Box, Button, Flex, Icon, Spacer, Text } from "@chakra-ui/react";
import * as React from "react";
import { MouseEventHandler, useEffect, useReducer, useState } from "react";
import { Step } from "./Step";
import _ from "lodash";
import { getStepProgressState } from "../../../features/step-progress/StepProgressSlice";
import { useAppSelector } from "../Hooks";
import { Colors } from "../../../types/Common";
import { HiArrowLeft } from "react-icons/hi";

export enum StepStates {
  NOT_STARTED = "not_started",
  CURRENT = "current",
  ERROR = "error",
  COMPLETED = "completed",
}

export interface ProgressStep {
  title: string;
  subtitle?: string;
  name: string;
  state?: StepStates;
  content: React.ReactNode;
  validator?: (payload?: any) => boolean;
}

interface StepProgressProps {
  submitBtnName?: string;
  steps: ProgressStep[];
  resetStepsAfterSubmit?: boolean;
  submitLoading?: boolean;
  onSubmit(): void;
}

export interface ReducerAction {
  index: number;
  state: StepStates;
}

function stepsReducer(
  steps: ProgressStep[],
  action: ReducerAction
): ProgressStep[] {
  return steps.map(function (step, i) {
    if (i < action.index) {
      step.state = StepStates.COMPLETED;
    } else if (i === action.index) {
      step.state = action.state;
    } else {
      step.state = StepStates.NOT_STARTED;
    }
    return step;
  });
}

export const StepProgress = (props: StepProgressProps) => {
  const {
    submitBtnName,
    onSubmit,
    steps,
    resetStepsAfterSubmit,
    submitLoading,
  } = props;
  const [state, dispatch] = useReducer(stepsReducer, steps);
  const stepProgressState = useAppSelector(getStepProgressState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const backButtonRef = React.useRef<HTMLButtonElement>(null);
  const nextButtonRef = React.useRef<HTMLButtonElement>(null);

  useEffect(function () {
    dispatch({ index: currentIndex, state: StepStates.CURRENT });
  }, []);

  useEffect(
    function () {
      if (currentIndex === 0) return;
      dispatch({ index: currentIndex - 2, state: StepStates.CURRENT });
      setCurrentIndex(currentIndex - 2);
    },
    [stepProgressState.backClickCount]
  );

  useEffect(
    function () {
      if (stepProgressState.nextClickCount > 0 && nextButtonRef.current) {
        nextButtonRef.current.click();
      }
    },
    [stepProgressState.nextClickCount]
  );

  const handlePreviousClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (currentIndex === 0) return;
    dispatch({ index: currentIndex - 1, state: StepStates.CURRENT });
    setCurrentIndex(currentIndex - 1);
  };

  const isCurrentIndexValid = (): boolean => {
    let isStateValid = true;
    const stepValidator = state[currentIndex].validator;
    if (stepValidator) isStateValid = stepValidator();
    return isStateValid;
  };

  const handleNextClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (currentIndex === steps.length - 1) return;
    const isStateValid = isCurrentIndexValid();
    dispatch({
      index: isStateValid ? currentIndex + 1 : currentIndex,
      state: isStateValid ? StepStates.CURRENT : StepStates.ERROR,
    });
    if (isStateValid) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleRandomClick = (index: number) => {
    if (index >= currentIndex) return;
    for (let i = currentIndex; i > index; --i) {
      dispatch({ index: i, state: StepStates.NOT_STARTED });
    }
    dispatch({ index, state: StepStates.CURRENT });
    setCurrentIndex(index);
  };

  const handleSubmitClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (currentIndex !== steps.length - 1) return;
    const isStateValid = isCurrentIndexValid();
    if (isStateValid) onSubmit();
    else dispatch({ index: currentIndex, state: StepStates.ERROR });
    if (resetStepsAfterSubmit) {
      dispatch({ index: 0, state: StepStates.CURRENT });
      setCurrentIndex(0);
    }
  };

  return (
    <Flex
      direction="column"
      mx="auto"
      maxW={"6xl"}
      py="10"
      px={{ base: "6", md: "8" }}
    >
      <Flex
        justify="center"
        align="center"
        as="ol"
        listStyleType="none"
        zIndex={1}
        w="100%"
        position="relative"
      >
        {state.map((step, index) => (
          <Step
            key={index}
            index={index}
            onClick={handleRandomClick}
            step={step}
          />
        ))}
      </Flex>
      <Spacer m={10} />
      <Box mb={5}>
        <Button
          bgColor={"white"}
          color={Colors.SmilieText}
          border={"1px solid " + Colors.SmilieText}
          isDisabled={currentIndex === 0 ? true : false}
          _hover={{ boxShadow: "inset -6.5em 0 0 0 white" }}
          onClick={handlePreviousClick}
          ref={backButtonRef}
        >
          <Icon
            as={HiArrowLeft}
            fontSize={"xl"}
            color={"#EE562A"}
            mr={2}
          ></Icon>
          Back
        </Button>
      </Box>
      <Box>{steps[currentIndex].content}</Box>
      <Spacer m={5} />
      <Flex justify="space-around" align="center">
        {currentIndex === steps.length - 1 ? (
          <Button
            bgColor={"white"}
            color={Colors.SmilieText}
            border={"1px solid " + Colors.SmilieText}
            isLoading={submitLoading}
            _hover={{ boxShadow: "inset 6.5em 0 0 0 white" }}
            onClick={_.throttle(handleSubmitClick, 200)}
          >
            {submitBtnName || "Submit"}
          </Button>
        ) : (
          <Button
            _hover={{ boxShadow: "inset 6.5em 0 0 0 rgba(0,128,64, 0.5)" }}
            onClick={handleNextClick}
            ref={nextButtonRef}
            hidden={currentIndex !== steps.length - 1}
          >
            Next
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
