import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Text,
  useTimeout,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../components/common/Hooks";
import { useGetGiftRecipientQuery } from "../../features/gift-recipients/api/GiftRecipientApiSlice";
import {
  setGiftRecipient,
  setTransactionId,
} from "../../features/gift-recipients/GiftRecipientSlice";
import { useLocation } from "react-router-dom";
import ClientPickGiftPage from "./ClientPickGift";
import LogoBar from "../../components/common/logo-bar/LogoBar";
import Lottie from "lottie-react";
import BackgroundTapGift from "../../assets/img/bg-tap-gift.png";
import HappyGiftBoxAnimation from "../../assets/lotties/happy-giftbox.json";
import BlueBalloonAnimation from "../../assets/lotties/blue-balloon.json";
import YellowBalloonAnimation from "../../assets/lotties/yellow-balloon.json";
import OrangeBalloonAnimation from "../../assets/lotties/orange-balloon.json";
import { ResponseStatusCode } from "../../types/GiftingResponse";
import { Colors } from "../../types/Common";

function ClientGiftSelectionLoadingPage() {
  const [isPreparingGift, setIsPreparingGift] = useState(true);
  const [isGiftTapped, setIsGiftTapped] = useState(false);
  const [isChooseGift, setIsChooseGift] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const getCode = () => {
    const pathName = location.pathname;
    const items = pathName.split("/");
    if (items.length > 0) return items[items.length - 1];

    return "";
  };

  const code = getCode();
  const { data, isLoading: getGiftRecipientLoading } =
    useGetGiftRecipientQuery(code);

  const handleTapGiftOnMouseOver = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const box: HTMLDivElement = event.currentTarget;
    box.style.cursor = "pointer";
  };

  const handleTapGiftOnMouseClick = () => {
    setIsGiftTapped(true);
  };

  const handleButtonOnMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const box: HTMLButtonElement = event.currentTarget;
    box.style.cursor = "pointer";
  };

  useTimeout(() => {
    setIsPreparingGift(false);
  }, 200);

  useEffect(() => {
    if (!!data && data.success) {
      dispatch(setTransactionId(code));
      dispatch(setGiftRecipient(data.giftRecipient));
    }
  }, [getGiftRecipientLoading]);

  const generateAnimations = () => {
    return (
      <>
        <Center hidden={!isPreparingGift}>Loading...</Center>
        {/* Show when gift is prepared AND when gift is not tapped */}
        <Center
          hidden={!(!isPreparingGift && !isGiftTapped)}
          onMouseOver={handleTapGiftOnMouseOver}
          onClick={handleTapGiftOnMouseClick}
          bottom={{"sm": "21vh", "md": "21vh", "lg": "21vh", "xl": "21vh", "2xl": "22vh"}}
          pos={"absolute"}
        >
          <Lottie
            animationData={HappyGiftBoxAnimation}
            loop={true}
            style={{ height: 400 }}
          ></Lottie>
        </Center>
        {/* Show when gift is prepared AND when gift is tapped AND when gift is not chosen */}
        <Center
          w="100%"
          h="100%"
          hidden={!(!isPreparingGift && isGiftTapped && !isChooseGift)}
        >
          <Grid w="100%" h="100%" templateColumns={"repeat(3, 1fr)"}>
            <GridItem>
              <Center>
                <Grid
                  templateRows={"repeat(2, 1fr)"}
                  templateColumns={"repeat(2, 1fr)"}
                >
                  <GridItem>
                    <Lottie
                      animationData={OrangeBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                  <GridItem>
                    <Lottie
                      animationData={YellowBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Lottie
                      animationData={BlueBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                </Grid>
              </Center>
            </GridItem>
            <GridItem>
              <Center h="100%">
                <VStack w="100%" h="100%" gap={3}>
                  <Box w="100%" h="20%"></Box>
                  <Box
                    w="100%"
                    h="60%"
                    textAlign={"justify"}
                    rounded={"lg"}
                    boxShadow={"lg"}
                    p={"8"}
                  >
                    {data?.giftRecipient?.message}
                  </Box>
                  <Center></Center>
                  <Center>
                    <Button
                      color={"white"}
                      bgColor={Colors.SmilieText}
                      onMouseEnter={handleButtonOnMouseEnter}
                      onClick={() => setIsChooseGift(true)}
                    >
                      CHOOSE GIFT
                    </Button>
                  </Center>
                </VStack>
              </Center>
            </GridItem>
            <GridItem>
              <Center>
                <Grid
                  templateRows={"repeat(2, 1fr)"}
                  templateColumns={"repeat(2, 1fr)"}
                >
                  <GridItem>
                    <Lottie
                      animationData={OrangeBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                  <GridItem>
                    <Lottie
                      animationData={YellowBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Lottie
                      animationData={BlueBalloonAnimation}
                      loop={true}
                      style={{ height: 300 }}
                    ></Lottie>
                  </GridItem>
                </Grid>
              </Center>
            </GridItem>
          </Grid>
        </Center>
      </>
    );
  };

  const generateSubContent = () => {
    if (isPreparingGift) {
      return (
        <Flex direction="column">
          <Center>
            <Text fontSize={24} fontWeight={"semibold"}>
              Preparing your gift!
            </Text>
          </Center>
          {generateAnimations()}
        </Flex>
      );
    } else if (!isPreparingGift && !isGiftTapped) {
      return (
        <Flex direction="column">
          <Center mb={{"sm": "72", "md": "72", "lg": "72", "xl": "72", "2xl": "72"}}>
            <Text
              fontSize={32}
              fontWeight={"bold"}
              casing={"uppercase"}
              color={Colors.SmilieText}
            >
              Tap to open your gift!
            </Text>
          </Center>
          {generateAnimations()}
        </Flex>
      );
    } else if (!isPreparingGift && isGiftTapped && !isChooseGift) {
      return (
        <Center w="100%" h="100%">
          {generateAnimations()}
        </Center>
      );
    } else {
      return (
        <Center h={"calc(100vh - 60px)"}>
          <Text
            align={"center"}
            fontSize={32}
            fontWeight={"bold"}
            casing={"uppercase"}
          >
            Unexpected error occurred! Please kindly contact administrator.
          </Text>
        </Center>
      );
    }
  };

  const generateContent = () => {
    if (isPreparingGift) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center h={"calc(100vh - 60px)"}>{generateSubContent()}</Center>
        </Flex>
      );
    } else if (
      !isPreparingGift &&
      data !== undefined &&
      data.code === ResponseStatusCode.GiftRecipientNotFound
    ) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center
            h={"calc(100vh - 60px)"}
            bgImage={BackgroundTapGift}
            bgSize={"contain"}
          >
            <VStack>
              <Text
                align={"center"}
                fontSize={32}
                fontWeight={"bold"}
                color={"red"}
                casing={"uppercase"}
              >
                Invalid Code!
              </Text>
              <Text
                align={"center"}
                fontSize={32}
                fontWeight={"bold"}
                casing={"uppercase"}
              >
                Please kindly check if the link is correct and retry again
              </Text>
              <Text
                align={"center"}
                fontSize={32}
                fontWeight={"bold"}
                casing={"uppercase"}
              >
                Thank you!
              </Text>
            </VStack>
          </Center>
        </Flex>
      );
    } else if (
      !isPreparingGift &&
      data !== undefined &&
      data.code === ResponseStatusCode.GiftRecipientFailure
    ) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center
            h={"calc(100vh - 60px)"}
            bgImage={BackgroundTapGift}
            bgSize={"contain"}
          >
            <VStack>
              <Text
                align={"center"}
                fontSize={32}
                fontWeight={"bold"}
                casing={"uppercase"}
              >
                {data.message}
              </Text>
            </VStack>
          </Center>
        </Flex>
      );
    } else if (
      !isPreparingGift &&
      data !== undefined &&
      data.giftRecipient !== undefined &&
      !isGiftTapped
    ) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center
            h={"calc(75vh - 60px)"}
            bgImage={BackgroundTapGift}
            bgSize={"contain"}
          >
            {generateSubContent()}
          </Center>
          <Center w="100%" h="25vh" bg={"#FFEBE5"}></Center>
        </Flex>
      );
    } else if (
      !isPreparingGift &&
      data !== undefined &&
      data.giftRecipient !== undefined &&
      isGiftTapped &&
      !isChooseGift
    ) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center h={"calc(100vh - 60px)"}>{generateSubContent()}</Center>
        </Flex>
      );
    } else if (
      !isPreparingGift &&
      data !== undefined &&
      data.giftRecipient !== undefined &&
      isGiftTapped &&
      isChooseGift
    ) {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center h={"calc(100vh) - 60px"}>
            <ClientPickGiftPage></ClientPickGiftPage>
          </Center>
        </Flex>
      );
    } else {
      return (
        <Flex direction={"column"}>
          <LogoBar />
          <Center
            h={"calc(100vh - 60px)"}
            bgImage={BackgroundTapGift}
            bgSize={"contain"}
          >
            <Text
              align={"center"}
              fontSize={32}
              fontWeight={"bold"}
              casing={"uppercase"}
            >
              Unexpected error occured! Please kindly contact administrator.
            </Text>
          </Center>
        </Flex>
      );
    }
  };

  return generateContent();
}

export default ClientGiftSelectionLoadingPage;
