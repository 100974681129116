import LogoCompany from "../../../assets/img/company-logo.svg";
import { Box, Image } from "@chakra-ui/react";

function LogoBar() {
  return (
    <Box h={"60px"} boxShadow={"lg"}>
      <Image
        src={LogoCompany}
        alt="Image not found"
        h={"60px"}
        fit={"cover"}
        px={"30px"}
        my={"5px"}
      ></Image>
    </Box>
  );
}

export default LogoBar;
