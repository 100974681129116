import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";

interface ConfirmPopoverProps extends PopoverProps {
  confirmText: string | React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  triggerButton: React.ReactNode;
}

const ConfirmPopover = (props: ConfirmPopoverProps) => {
  const { confirmText, onConfirm, onCancel, triggerButton, ...rest } = props;
  const contentBgColor = useColorModeValue("gray.300", "gray.500");

  return (
    <Popover variant={"confirm"} autoFocus={false} {...rest}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{triggerButton}</PopoverTrigger>
          <PopoverContent w={"260px"} bg={contentBgColor}>
            <PopoverArrow bg={contentBgColor} onClick={onClose} />
            <PopoverCloseButton />
            <PopoverBody>
              <Box whiteSpace={"pre-wrap"} p={"15px 0"}>
                {confirmText}
              </Box>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => {
                    onClose();
                    if (!!onCancel) {
                      onCancel();
                    }
                  }}
                  colorScheme={"gray"}
                  h={"30px"}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    onConfirm();
                  }}
                  colorScheme={"red"}
                  h={"30px"}
                >
                  Yes
                </Button>
              </ButtonGroup>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ConfirmPopover;
