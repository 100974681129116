import { createStandaloneToast } from "@chakra-ui/react";

const { toast } = createStandaloneToast();

export const successToast = (description: string, title?: string) => {
  toast({
    title: title || "Success",
    status: "success",
    description,
    duration: 2000,
    position: "top",
  });
};

export const errorToast = (description: string, title?: string) => {
  toast({
    title: title || "Error",
    status: "error",
    description,
    duration: 2000,
    position: "top"
  });
};

export const warningToast = (description: string, title?: string) => {
  toast({
    title: title || "Warning",
    status: "warning",
    description,
    duration: 2000,
    position: "top"
  });
};
