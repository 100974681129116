import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../components/common/Store";
import { GiftCategoriesDto, GiftSubCategoriesDto } from "./api/dto/GiftCategoryDto";
import { GiftDto } from "./api/dto/GiftDto";

type GiftState = {
  giftCategories: GiftCategoriesDto[],
  giftSubCategories: GiftSubCategoriesDto[],
  gifts: GiftDto[]
}

const initialState: GiftState = {
  giftCategories: [],
  giftSubCategories: [],
  gifts: []
};

const giftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
});

export const {
  reset,
} = giftSlice.actions;

export const getGiftState = (state: RootState) => state.gift;

export default giftSlice.reducer;
