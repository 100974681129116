import _ from "lodash";
import { RouteObject } from "react-router-dom";
import FooterLayout from "../components/layout/FooterLayout";
import Layout from "../components/layout/Layout";
import {
  CLIENT_ADMIN,
  CLIENT_USER,
  SUPER_ADMIN,
} from "../constants/RoleConstant";
import ClientGiftSelectionLoadingPage from "../pages/clients/ClientGiftSelectionLoading";
import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import NotFoundPage from "../pages/NotFound";
import { clientAdminRoutes } from "./ClientAdminRoute";
import { clientUserRoutes } from "./ClientUserRoute";
import { superAdminRoutes } from "./SuperAdminRoute";

export const activeRoutes: Map<string, RouteObject[]> = new Map([
  [SUPER_ADMIN, superAdminRoutes],
  [CLIENT_ADMIN, clientAdminRoutes],
  [CLIENT_USER, clientUserRoutes]
]);

export const getRoutes = (routes: RouteObject[]): RouteObject[] => {
  return [
    {
      path: "Login",
      element: <LoginPage />,
    },
    {
      path: "PickGift/:code",
      element: <ClientGiftSelectionLoadingPage />
    },
    {
      path: "/",
      element: <FooterLayout />,
      children: [
        {
          index: true,
          element: <Layout />,
        },
        {
          element: <Layout />,
          children: [
            {
              path: "*",
              element: <NotFoundPage />,
            },
            ...routes
          ],
        },
        {
          path: "Logout",
          element: <LogoutPage />,
        },
      ],
    },
  ];
};
