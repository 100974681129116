import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import _ from "lodash";
import { useUpdateGiftRecipientMessageMutation } from "../../api/GiftRecipientApiSlice";
import { successToast } from "../../../../utils/ToastUtil";

type GiftRecipientModalContentProps = {
  editMode?: boolean;
  giftRecipientId: number;
  message?: string;
  openModal: { on: () => void; off: () => void; toggle: () => void };
};

const GiftRecipientModalContent = (props: GiftRecipientModalContentProps) => {
  const { editMode, giftRecipientId, message, openModal } = props;
  const [messageValue, setMessageValue] = useState<string>(message || "");
  const [updateGiftRecipientMessage, { isLoading: updateLoading }] =
    useUpdateGiftRecipientMessageMutation();

  const submitGiftRecipientMessage = async () => {
    let result = await updateGiftRecipientMessage({
      giftRecipientId: giftRecipientId || 0,
      message: messageValue,
    });

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast("Gift Recipient Message Update Success!");
    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Message to Gift Recipient</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl mt="4">
            <FormLabel>Message</FormLabel>
            <Textarea
              value={messageValue}
              resize={"none"}
              rows={10}
              placeholder={"Message"}
              onChange={(e) => setMessageValue(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitGiftRecipientMessage}
            colorScheme="blue"
            isLoading={updateLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default GiftRecipientModalContent;
