import { useColorModeValue, Center, Flex, Button, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../features/authentication/api/AuthApiSlice";

function LogoutPage() {
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    logout().then((res) => {
      if ("error" in res) {
        return;
      }
      localStorage.removeItem("token");
      localStorage.removeItem("token_expiry");
      navigate("/Login");
    });
  }, []);

  return (
    <Center h="100%" position="relative" minW="450px">
      <Flex
        zIndex="overlay"
        direction="column"
        borderRadius="15px"
        minW="400px"
        p="40px"
        bg={bgForm}
        boxShadow={useColorModeValue(
          "0px 5px 14px rgba(0, 0, 0, 0.05)",
          "unset"
        )}
      >
        <Text
          fontSize="x-large"
          color={textColor}
          fontWeight="bold"
          textAlign="center"
          mb="48px"
        >
          Logout
        </Text>

        <Button isLoading={true} colorScheme={"blue"} />
      </Flex>
    </Center>
  );
}

export default LogoutPage;
