import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./components/common/Store";
import RoutesLayout from "./components/layout/RoutesLayout";

function App() {
  return (
    <ChakraProvider>
      <ReduxProvider store={store}>
        <RoutesLayout />
      </ReduxProvider>
    </ChakraProvider>
  );
}

export default App;
