import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../components/common/Store";
import { GiftRecipientGiftSelectionDto } from "./api/dto/GiftRecipientDto";

type GiftRecipientState = {
  transactionId: string,
  giftRecipient?: GiftRecipientGiftSelectionDto,
  selectedGiftSubCategoryId?: number,
  selectedGiftId?: number,
  confirmedSelectedGiftId?: number
}

const initialState: GiftRecipientState = {
  transactionId: "",
  giftRecipient: undefined,
  selectedGiftSubCategoryId: undefined,
  selectedGiftId: undefined,
  confirmedSelectedGiftId: undefined
};

const giftRecipientSlice = createSlice({
  name: "giftRecipient",
  initialState,
  reducers: {
    setTransactionId(state, action: PayloadAction<string>) {
      state.transactionId = action.payload;
    },
    setGiftRecipient(state, action: PayloadAction<GiftRecipientGiftSelectionDto>) {
      state.giftRecipient = action.payload;
    },
    setSelectedGiftSubCategoryId(state, action: PayloadAction<number>) {
      state.selectedGiftSubCategoryId = action.payload;
    },
    setSelectedGiftId(state, action: PayloadAction<number>) {
      state.selectedGiftId = action.payload;
    },
    setConfirmedSelectedGiftId(state, action: PayloadAction<number>) {
      state.confirmedSelectedGiftId = action.payload;
    },
    setClientRecipientPickUpOption(state, action: PayloadAction<number>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.pickUpOption = action.payload;
      }
    },
    setClientRecipientFirstName(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.firstName = action.payload;
      }
    },
    setClientRecipientLastName(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.lastName = action.payload;
      }
    },
    setClientRecipientPhoneNumber(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.phoneNumber = action.payload;
      }
    },
    setClientRecipientAddressLineOne(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.addresses[0].addressLineOne = action.payload;
      }
    },
    setClientRecipientAddressLineTwo(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.addresses[0].addressLineTwo = action.payload;
      }
    },
    setClientRecipientPostalCode(state, action: PayloadAction<string>) {
      if (state.giftRecipient?.clientRecipient !== undefined) {
        state.giftRecipient.clientRecipient.addresses[0].postalCode = action.payload;
      }
    },
    reset(state) {
      return initialState;
    },
  },
});

export const {
  setTransactionId,
  setGiftRecipient,
  setSelectedGiftSubCategoryId,
  setSelectedGiftId,
  setConfirmedSelectedGiftId,
  setClientRecipientPickUpOption,
  setClientRecipientFirstName,
  setClientRecipientLastName,
  setClientRecipientPhoneNumber,
  setClientRecipientAddressLineOne,
  setClientRecipientAddressLineTwo,
  setClientRecipientPostalCode,
  reset,
} = giftRecipientSlice.actions;

export const getGiftRecipientState = (state: RootState) => state.giftRecipient;

export default giftRecipientSlice.reducer;
