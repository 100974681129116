import { CompactTable } from "@table-library/react-table-library/compact";
import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { Column } from "@table-library/react-table-library/types/compact";
import {
  TreeExpandClickTypes,
  useTree,
} from "@table-library/react-table-library/tree";
import { usePagination } from "@table-library/react-table-library/pagination";
import { FaChevronDown, FaChevronRight } from "react-icons/all";
import _ from "lodash";
import LoadingSpinner from "../../components/common/spinner/LoadingSpinner";
import { useGetGiftCategoriesQuery } from "../../features/gifts/api/GiftApiSlice";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";

export default function GiftMainCategoryPage() {
  const { data: apiData, isLoading } = useGetGiftCategoriesQuery();

  const nodes = apiData?.success
    ? apiData?.giftCategories.map((category) => {
        return {
          id: category.giftCategoryId.toString(),
          name: category.name,
          isActive: category.isActive,
          createdAt: category.createdAt,
          createdBy: category.createdBy,
          updatedAt: category.updatedAt,
          updatedBy: category.updatedBy,
        };
      })
    : [];

  const resizer = {
    resizerHighlight: "#dde2bf",
    resizerWidth: 2,
  };

  const COLUMNS: Column[] = [
    {
      label: "ID",
      renderCell: (item) => item.id,
      resize: resizer,
    },
    {
      label: "Name",
      renderCell: (item) => item.name,
      resize: resizer,
    },
    {
      label: "Is Active",
      renderCell: (item) => (item.isActive ? "Yes" : "No"),
      resize: resizer,
    },
    {
      label: "Created At",
      renderCell: (item) => moment(item.createdAt).format(DateFormat),
      resize: resizer,
    },
    {
      label: "Created By",
      renderCell: (item) => item.createdBy,
      resize: resizer,
    },
    {
      label: "Updated At",
      renderCell: (item) => moment(item.updatedAt).format(DateFormat),
      resize: resizer,
    },
    {
      label: "Updated By",
      renderCell: (item) => item.updatedBy,
      resize: resizer,
    },
  ];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
       --data-table-library_grid-template-columns: repeat(${COLUMNS.length}, auto);
      .animate {
        grid-column: 1 / -1;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const data = { nodes };

  const tree = useTree(
    data,
    {
      onChange: (action, state) => console.log(action, state),
    },
    {
      treeIcon: {
        iconRight: <FaChevronRight />,
        iconDown: <FaChevronDown />,
      },
      clickType: TreeExpandClickTypes.ButtonClick,
    }
  );

  const pagination = usePagination(data, {
    state: { size: 20 },
  });

  return (
    <Flex direction="column" w="full" mt={"60px"}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box>
          <Box color="#A7A1AE" bg="#1F2739">
            <CompactTable
              columns={COLUMNS}
              data={data}
              theme={theme}
              tree={tree}
              pagination={pagination}
              layout={{ custom: true, horizontalScroll: true }}
            />
          </Box>
          <Spacer m={5} />
          <Flex flexWrap="wrap" justify="flex-end" align="baseline">
            <Text mr={2}>Page:</Text>
            {pagination.state
              .getPages(data.nodes)
              .map((_: any, index: number) => (
                <Button
                  key={index}
                  fontWeight={
                    pagination.state.page === index ? "bold" : "normal"
                  }
                  bg={
                    pagination.state.page === index
                      ? "blackAlpha.400"
                      : "transparent"
                  }
                  color={pagination.state.page === index ? "white" : "black"}
                  _hover={{
                    boxShadow: "0 0.5em 0.5em -0.5em rgba(64, 128, 64, 0.5)",
                  }}
                  onClick={() => pagination.fns.onSetPage(index)}
                >
                  {index + 1}
                </Button>
              ))}
          </Flex>
        </Box>
      )}
    </Flex>
  );
}
