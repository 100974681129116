import {
  Avatar,
  Divider,
  Flex,
  IconButton,
  Modal,
  Text,
  useBoolean
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import GiftSubCategoryEditContent from "./GiftSubCategoryEditContent";
import { useDeleteGiftSubCategoryMutation } from "../api/GiftApiSlice";
import { GiftSubCategoryCreateDto } from "../api/dto/GiftCategoryDto";
import ConfirmPopover from "../../../components/common/popover/ConfirmPopover";
import { useState } from "react";
import { successToast } from "../../../utils/ToastUtil";

const itemProps = {
  alignItems: "center",
  marginLeft: "10%",
};

const labelProps = {
  width: "80px",
};

const textProps = {
  fontWeight: "bold",
  marginLeft: "20px",
  width: "200px",
};

interface GiftSubCategoryInfoPopContentProps extends GiftSubCategoryCreateDto {
  giftCategory: string;
  isForOnboarding: boolean;
  imagePath?: string;
}

const GiftSubCategoryInfoPopContent = (props: GiftSubCategoryInfoPopContentProps) => {
  const [deleteGiftSubCategory, { isLoading: deleteLoading }] =
    useDeleteGiftSubCategoryMutation();
  const { giftSubCategoryId, name, giftCategory, giftCategoryId, isForOnboarding, imagePath, file } = props;
  const [editOpen, setEditOpen] = useBoolean();
  const [deletePopVis, setDeletePopVis] = useState(false);

  const handleDelete = async () => {
    const result = await deleteGiftSubCategory({ giftCategoryId, giftSubCategoryId });
    if ("error" in result) {
      return;
    }

    successToast("Gift Sub Category Delete Success!");
  };

  return (
    <>
      <Modal isOpen={editOpen} onClose={setEditOpen.off}>
        <GiftSubCategoryEditContent
          setEditOpen={setEditOpen}
          giftSubCategoryId={giftSubCategoryId}
          name={name}
          giftCategory={giftCategory}
          giftCategoryId={giftCategoryId}
          isForOnboarding={isForOnboarding}
          imagePath={imagePath}
          file={file}
        />
      </Modal>
      <Flex direction={"column"} alignItems={"center"} p={"20px 15px"}>
        <Avatar name={name} size={"2xl"} src={imagePath} />
        <Flex mt={"20px"} gap={"25px"}>
          <IconButton
            rounded={"full"}
            aria-label={"edit"}
            icon={<EditIcon />}
            color={"rgb(62,169,221)"}
            bg={"rgb(233,243,252)"}
            _hover={{
              backgroundColor: "rgb(233,243,252)",
            }}
            onClick={setEditOpen.on}
          />
          <ConfirmPopover
            isOpen={deletePopVis}
            onClose={() => setDeletePopVis(false)}
            onOpen={() => setDeletePopVis(true)}
            confirmText={`Are you sure to delete ${name}`}
            onConfirm={handleDelete}
            triggerButton={
              <IconButton
                isLoading={deleteLoading}
                rounded={"full"}
                _hover={{ backgroundColor: "rgb(255,201,192)" }}
                aria-label={"update"}
                icon={<DeleteIcon />}
                color={"rgb(252,54,52)"}
                bg={"rgb(255,201,192)"}
              />
            }
          ></ConfirmPopover>
        </Flex>
        <Divider mt={"20px"} />
        <Flex
          direction={"column"}
          alignSelf={"flex-start"}
          mt={"10px"}
          gap={"10px"}
        >
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Name
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {name}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Category
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {giftCategory}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default GiftSubCategoryInfoPopContent;
