import { Box, Circle, keyframes, VStack } from "@chakra-ui/react";
import { ProgressStep, StepStates } from "./StepProgress";

interface StepProps {
  index: number;
  step: ProgressStep;
  onClick(index: number): void;
}

export const Step = (props: StepProps) => {
  const { index, onClick, step } = props;

  const stepMove = keyframes`
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    `;

  const springUp = keyframes`
      0% {
        transform: scale(1);
      }
      10% {
        transform: scale(0.975);
      }
      40% {
        transform: scale(1.4);
      }
      50%, 100% {
        transform: scale(1.2);
      }
    `;

  const springDown = keyframes`
      0% {
        transform: scale(1.2);
      }
      35%, 100% {
        transform: scale(1);
      }
    `;

  const shake = keyframes`
      0% {
        transform: translateX(0);
      }
      15% {
        transform: translateX(0.2rem);
      }

      30%, 60% {
        transform: translateX(-0.4rem);
      }
      45%, 75% {
        transform: translateX(0.4rem);
      }
      90% {
        transform: translateX(-0.2rem);
      }
      100% {
        transform: rotate(360deg)
      }
    `;

  return (
    <VStack
      as="li"
      flex="1"
      text-align="center"
      position="relative"
      _first={{ _before: { content: "none" } }}
      _before={{
        content: '""',
        position: "absolute",
        top: "50%",
        height: "3px",
        width: "100%",
        right: "50%",
        zIndex: "-1",
        transform: "translateY(-50%)",
        bgGradient:
          step.state === StepStates.NOT_STARTED
            ? "linear(to-r, #3a3b3a, #787878)"
            : "linear(to-r, #EE562A, #EE562A)",
        bgSize: "200% 100%",
        animation: `${stepMove} 500ms ease forwards`,
      }}
    >
      {step.state !== StepStates.CURRENT &&
        step.state !== StepStates.COMPLETED &&
        step.state !== StepStates.ERROR && (
          <Circle size="36px" bg="gray.500" color="white">
            {index + 1}
          </Circle>
        )}

      {step.state === StepStates.CURRENT && (
        <Circle
          animation={`${springUp} 350ms ease forwards`}
          size="36px"
          bg="#EE562A"
          color="white"
        >
          {index + 1}
        </Circle>
      )}

      {step.state === StepStates.COMPLETED && (
        <Circle
          animation={`${springDown} 350ms ease forwards`}
          size="36px"
          bg="#EE562A"
          color="white"
          _hover={{ bg: "#42997f" }}
          onClick={() => {
            onClick(index);
          }}
        >
          {index + 1}
        </Circle>
      )}

      {step.state === StepStates.ERROR && (
        <Circle
          animation={`${shake} 350ms ease-in-out forwards`}
          size="36px"
          bg="#bb392d"
          color="white"
        >
          !
        </Circle>
      )}

      <VStack
        position="absolute"
        top="120%"
        left="50%"
        transform="translateX(-50%)"
      >
        <Box>{step.title}</Box>
        {step.subtitle && <Box>{step.subtitle}</Box>}
      </VStack>
    </VStack>
  );
};
