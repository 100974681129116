import {
  configureStore,
  isRejected,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import authReducer from "../../features/authentication/AuthSlice";
import giftReducer from "../../features/gifts/GiftSlice";
import companyReducer from "../../features/companies/CompanySlice";
import userReducer from "../../features/users/UserSlice";
import campaignReducer from "../../features/campaigns/CampaignSlice";
import clientReducer from "../../features/clients/ClientSlice";
import giftRecipientReducer from "../../features/gift-recipients/GiftRecipientSlice";
import stepProgressReducer from "../../features/step-progress/StepProgressSlice";
import { apiSlice } from "../../services/ApiSlice";
import { ResponseStatusCode } from "../../types/GiftingResponse";
import { errorToast } from "../../utils/ToastUtil";

const rtkQueryResponseHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      console.warn("We got a rejected action!");
      return;
    }
    if (action?.payload?.code != null) {
      const responseCode = action?.payload?.code;
      if (responseCode !== ResponseStatusCode.Success) {
        if (responseCode === ResponseStatusCode.Unauthorized) {
          errorToast(action?.payload?.message);
          localStorage.removeItem("token");
          localStorage.removeItem("token_expiry");
          window.location.pathname = "/Login";
        } else if (responseCode !== ResponseStatusCode.GiftRecipientNotFound && responseCode !== ResponseStatusCode.GiftRecipientFailure) {
          errorToast(action?.payload?.message);
        }
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    auth: authReducer,
    gift: giftReducer,
    company: companyReducer,
    user: userReducer,
    campaign: campaignReducer,
    client: clientReducer,
    giftRecipient: giftRecipientReducer,
    stepProgress: stepProgressReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(rtkQueryResponseHandler)
      .concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
