import { Outlet } from "react-router-dom";
import Sidebar from "../common/Sidebar";

function Layout(props: any) {
  return (
    <Sidebar>
      <main style={{ padding: "1rem 0" }}>
        <Outlet />
      </main>
    </Sidebar>
  );
}

export default Layout;
