import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../components/common/Store";
import { UserProfile } from "../../types/UserProfile";

type AuthState = {
  userInfo: UserProfile;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {} as AuthState,
  reducers: {
    setUserProfile(state, action) {
      state.userInfo = action.payload;
    }
  },
});

export const { setUserProfile } = authSlice.actions;

export const getUserInfoState = (state: RootState) => state.auth;

export default authSlice.reducer;
