import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";

interface AlertProps
  extends Omit<AlertDialogProps, "children" | "leastDestructiveRef"> {
  title: string;
  content: string;
  onConfirm(): void;
}

export default function Alert({
  title,
  content,
  onConfirm,
  isOpen,
  onClose,
  ...props
}: AlertProps) {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      {...props}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{content}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
          >
            No
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
