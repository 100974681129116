import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCreateUserMutation } from "../../api/UserApiSlice";
import { successToast } from "../../../../utils/ToastUtil";
import { useGetCompanyDropDownListQuery } from "../../../companies/api/CompanyApiSlice";
import { UserCreateRequest, UserRoleEnum } from "../../api/dto/UserDto";
import { SmilieCompanyId } from "../../../../constants/ValueConstant";
import { getUserInfoState } from "../../../authentication/AuthSlice";
import { useAppSelector } from "../../../../components/common/Hooks";

type UserModalAddContentProps = {
  openModal: { on: () => void; off: () => void; toggle: () => void };
};

const UserModalAddContent = (props: UserModalAddContentProps) => {
  const userInfoState = useAppSelector(getUserInfoState);
  const isSmilie: boolean = userInfoState.userInfo.companyId === SmilieCompanyId;
  const { openModal } = props;
  const { data: companiesApiData } = useGetCompanyDropDownListQuery();
  const [companyIdValue, setCompanyIdValue] = useState<number>((!isSmilie ? userInfoState.userInfo.companyId : 0));
  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [firstNameValue, setFirstNameValue] = useState<string>("");
  const [lastNameValue, setLastNameValue] = useState<string>("");
  const [isAdminValue, setIsAdminValue] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [createUser, { isLoading: submitLoading }] = useCreateUserMutation();

  const submitUser = async () => {
    if (
      companyIdValue === 0 ||
      emailValue === "" ||
      passwordValue === "" ||
      firstNameValue === ""
    ) {
      setIsError(true);
      return;
    }

    let request: UserCreateRequest = {
      companyId: companyIdValue,
      email: emailValue,
      password: passwordValue,
      firstName: firstNameValue,
      lastName: lastNameValue,
      userRoleId: isAdminValue
        ? UserRoleEnum.ClientAdmin
        : UserRoleEnum.ClientUser,
    };

    if (companyIdValue === SmilieCompanyId) {
      request.userRoleId = UserRoleEnum.SuperAdmin;
    }

    let result = await createUser(request);

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast("User Add Success!");

    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add User</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl isRequired isInvalid={isError && companyIdValue === 0}>
            <FormLabel>Company</FormLabel>
            <Select
              onChange={(e) => setCompanyIdValue(parseInt(e.target.value))}
              value={!isSmilie ? userInfoState.userInfo.companyId : companyIdValue}
              isDisabled={!isSmilie}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {companiesApiData?.success
                ? companiesApiData?.companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))
                : ""}
            </Select>
            {isError && companyIdValue === 0 && (
              <FormErrorMessage>Company is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && emailValue === ""}
            mt="4"
          >
            <FormLabel>Email</FormLabel>
            <Input
              placeholder={"Email"}
              value={emailValue}
              autoComplete="new-password"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setEmailValue(e.target.value);
              }}
            />
            {isError && emailValue === "" && (
              <FormErrorMessage>Email is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && passwordValue === ""}
            mt="4"
          >
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder={"Password"}
              autoComplete="new-password"
              value={passwordValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setPasswordValue(e.target.value);
              }}
            />
            {isError && passwordValue === "" && (
              <FormErrorMessage>Password is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && firstNameValue === ""}
            mt="4"
          >
            <FormLabel>First Name</FormLabel>
            <Input
              placeholder={"First Name"}
              value={firstNameValue}
              autoComplete="new-password"
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setFirstNameValue(e.target.value);
              }}
            />
            {isError && firstNameValue === "" && (
              <FormErrorMessage>First Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Last Name</FormLabel>
            <Input
              placeholder={"Last Name"}
              value={lastNameValue}
              autoComplete="new-password"
              onChange={(e) => {
                setLastNameValue(e.target.value);
              }}
            />
          </FormControl>
          <FormControl hidden={companyIdValue === SmilieCompanyId} mt="4">
            <FormLabel>Is Admin</FormLabel>
            <Switch
              id="isAdmin"
              isChecked={isAdminValue}
              onChange={(e) => {
                setIsAdminValue(e.target.checked);
              }}
            ></Switch>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitUser}
            colorScheme="blue"
            isLoading={submitLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default UserModalAddContent;
