import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../features/authentication/api/AuthApiSlice";
import Logo from "../assets/img/company-logo.svg";
import { setUserProfile } from "../features/authentication/AuthSlice";
import { useAppDispatch } from "../components/common/Hooks";
import { useGetUserProfileMutation } from "../features/users/api/UserApiSlice";
import { errorToast } from "../utils/ToastUtil";

export const checkTokenExpiry = (): boolean => {
  if (
    localStorage.getItem("token_expiry") === null ||
    localStorage.getItem("token") === null
  ) {
    return false;
  } else {
    const token_expiry = Date.parse(localStorage.getItem("token_expiry") || "");
    const now = Date.parse(new Date().toString());
    return token_expiry - now >= 0;
  }
};

export default function LoginPage() {
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmail, setIsEmail] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();
  const [getUserProfile] = useGetUserProfileMutation();

  const handleSignIn = () => {
    if (!email) setIsEmail(false);
    else setIsEmail(true);
    if (!password) setIsPassword(false);
    else setIsPassword(true);

    login({ email: email, password: password }).then((res) => {
      if ("error" in res) {
        return;
      }

      if (res?.data?.success) {
        const now = new Date();
        const token_expiry = now.setDate(now.getDate() + 1);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("token_expiry", new Date(token_expiry).toString());
        setTokenAndGetUserInfo();
        return;
      } else {
        errorToast(res?.data?.message);
      }
    });
  };

  const setTokenAndGetUserInfo = (token?: string) => {
    getUserProfile().then((res) => {
      if ("error" in res) {
        return;
      }

      dispatch(setUserProfile(res.data.user));
    });

    navigate("/Dashboard");
  };

  useEffect(() => {
    if (localStorage.getItem("token") && checkTokenExpiry()) {
      setTokenAndGetUserInfo();
      return;
    }
  }, []);

  return (
    <>
      <SimpleGrid h="100vh" columns={2}>
        <Center bgColor="lightyellow">
          <Center
            width="360px"
            height="100px"
            bgSize="contain"
            bgRepeat={"no-repeat"}
            bgImage={Logo}
          ></Center>
        </Center>
        <Center>
          <Flex
            zIndex="overlay"
            direction="column"
            w={"100%"}
            p={{sm: "20px", md: "80px"}}
            bg={bgForm}
          >
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="left"
              mb="48px"
            >
              Welcome Back
            </Text>
            <FormControl>
              <FormControl ms="4px" mb="24px" isInvalid={!isEmail}>
                <FormLabel fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="text"
                  placeholder="User name"
                  size="lg"
                />
                <FormErrorMessage>Email is required.</FormErrorMessage>
              </FormControl>
              <FormControl ms="4px" mb="24px" isInvalid={!isPassword}>
                <FormLabel fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  placeholder="Password"
                  size="lg"
                />
                <FormErrorMessage>Password is required.</FormErrorMessage>
              </FormControl>
              <Button
                fontSize="sm"
                colorScheme="teal"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                onClick={handleSignIn}
              >
                SIGN IN
              </Button>
            </FormControl>
          </Flex>
        </Center>
      </SimpleGrid>
    </>
  );
}
