import { Navigate, RouteObject } from "react-router-dom";
import CompanyListPage from "../pages/companies/CompanyList";
import DashboardPage from "../pages/Dashboard";
import GiftMainCategoryPage from "../pages/gifts/GiftCategory";
import GiftCategoryBoardPage from "../pages/gifts/GiftCategoryBoard";
import ProfilePage from "../pages/Profile";
import ChangePasswordPage from "../pages/ChangePassword";
import CampaignListPage from "../pages/campaigns/CampaignList";
import ClientBoardPage from "../pages/clients/ClientBoard";
import GiftListPage from "../pages/gifts/GiftList";
import GiftRecipientListPage from "../pages/gift-recipients/GiftRecipientList";
import UserListPage from "../pages/users/UserList";

export const superAdminRoutes: RouteObject[] = [
  {
    path: "Dashboard",
    element: <DashboardPage />,
  },
  {
    path: "Companies",
    children: [
      {
        index: true,
        element: <Navigate to={"CompanyList"} />,
      },
      {
        path: "CompanyList",
        element: <CompanyListPage />,
      },
      {
        path: "Clients",
        element: <ClientBoardPage />,
      },
    ],
  },
  {
    path: "GiftCategories",
    children: [
      {
        index: true,
        element: <Navigate to={"GiftMainCategories"} />,
      },
      {
        path: "GiftMainCategories",
        element: <GiftMainCategoryPage />,
      },
      {
        path: "Manage",
        element: <GiftCategoryBoardPage />,
      },
    ],
  },
  {
    path: "Campaigns",
    children: [
      {
        index: true,
        element: <Navigate to={"CampaignList"} />,
      },
      {
        path: "CampaignList",
        element: <CampaignListPage />,
      },
    ],
  },
  {
    path: "Gifts",
    children: [
      {
        index: true,
        element: <Navigate to={"Gifts"} />,
      },
      {
        path: "Gifts",
        element: <GiftListPage />,
      },
      {
        path: "GiftRecipients",
        element: <GiftRecipientListPage />,
      },
    ],
  },
  {
    path: "Users",
    children: [
      {
        index: true,
        element: <Navigate to={"Users"} />,
      },
      {
        path: "Users",
        element: <UserListPage />,
      },
    ],
  },
  {
    path: "Settings",
    children: [
      {
        index: true,
        element: <Navigate to={"Profile"} />,
      },
      {
        path: "Profile",
        element: <ProfilePage />,
      },
      {
        path: "ChangePassword",
        element: <ChangePasswordPage />,
      },
    ],
  },
];
