import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import Alert from "../alert/Alert";
import { useAppDispatch, useAppSelector } from "../Hooks";
import { setUserProfile } from "../../../features/authentication/AuthSlice";
import { authenticate } from "../../layout/FooterLayout";
import { MdEmail } from "react-icons/md";
import { useGetUserProfileMutation } from "../../../features/users/api/UserApiSlice";

const SidebarProfileItem = ({ ...rest }: FlexProps) => {
  const [alertVis, setAlertVis] = useState(false);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const [getUserProfile] = useGetUserProfileMutation();
  const dispatch = useAppDispatch();

  const logout = () => {
    setAlertVis(false);
    window.location.href = `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ""}/logout`;
  };

  const getUserInformation = () => {
    getUserProfile().then((res) => {
      if ("error" in res) {
        return;
      }

      if (res?.data?.success) {
        dispatch(setUserProfile(res.data.user));
      }
    });
  };

  useEffect(() => {
    if (authenticate()) {
      getUserInformation();
    }
  }, []);

  const displayText = (icon: IconType, text: string) => {
    return (
      <Text
        mt={"5px"}
        fontWeight={"200"}
        fontSize={"13px"}
        display={"flex"}
        alignItems={"center"}
      >
        <Icon as={icon} mr={"5px"} />
        {text ?? "—— ——"}
      </Text>
    );
  };

  const AccountBoard = () => {
    return (
      <Flex p={"10px 20px"} direction={"column"} alignItems={"center"}>
        <Avatar
          w={"70px"}
          h={"70px"}
          size={"lg"}
          name={userInfo?.firstName + " " + userInfo?.lastName}
        />
        <Text m={"0 auto"} mt={"20px"} fontWeight={"700"} fontSize={"16px"}>
          {userInfo?.firstName + " " + userInfo?.lastName}
        </Text>
        <Flex ml={"10px"} direction={"column"} fontFamily={"Times New Roman"}>
          {userInfo?.email && displayText(MdEmail, userInfo?.email)}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Popover placement="right-start">
        <PopoverTrigger>
          <Box
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
          >
            <Flex
              align="center"
              p="4"
              role="group"
              cursor="pointer"
              color="black"
              _hover={{
                bg: "gray.400",
                color: "white",
              }}
              gap={"10px"}
              {...rest}
            >
              <Avatar
                size={"sm"}
                name={userInfo?.firstName + " " + userInfo?.lastName}
              />
              <Text fontWeight={"700"}>
                {userInfo?.firstName + " " + userInfo?.lastName}
              </Text>
            </Flex>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          p={"10px 5px"}
          _focusVisible={{
            outline: "none",
          }}
        >
          {AccountBoard()}
          <Divider mb={"10px"} />
          <Button
            borderRadius={0}
            bg={"transparent"}
            onClick={() => setAlertVis(true)}
          >
            <Box
              fontSize={"18px"}
              m={"0 auto"}
              color={"red.600"}
              fontWeight={700}
            >
              Logout
            </Box>
          </Button>
        </PopoverContent>
      </Popover>
      <Alert
        title={"Logout"}
        content={"Are you sure to Logout?"}
        onConfirm={logout}
        isOpen={alertVis}
        onClose={() => setAlertVis(false)}
      />
    </>
  );
};

export default SidebarProfileItem;
