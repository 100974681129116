import { useEffect, useState } from "react";
import {
  Button,
  Text,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  useBoolean,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import _ from "lodash";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { useGetCompanyWithClientsListQuery } from "../../features/companies/api/CompanyApiSlice";
import { useDeleteClientMutation } from "../../features/clients/api/ClientApiSlice";
import ClientModalContent from "../../features/clients/components/modal/ClientModalContent";
import { ClientBasicInfoDto } from "../../features/clients/api/dto/ClientDto";
import { CompanyBasicInfoWithClientDto } from "../../features/companies/api/dto/CompanyDto";
import ClientRecipientDetailCell from "../../features/clients/components/ClientRecipientDetailCell";
import { successToast } from "../../utils/ToastUtil";
import ClientRecipientAddContent from "../../features/clients/components/ClientRecipientAddContent";
import ClientRecipientInfoPopContent from "../../features/clients/components/ClientRecipientInfoPopContent";
import { getUserInfoState } from "../../features/authentication/AuthSlice";
import { useAppSelector } from "../../components/common/Hooks";
import { SmilieCompanyId } from "../../constants/ValueConstant";

const ClientBoardPage = () => {
  const userInfoState = useAppSelector(getUserInfoState);
  const isSmilie: boolean = userInfoState.userInfo.companyId === SmilieCompanyId;
  const { data } = useGetCompanyWithClientsListQuery();
  const companyList = data?.companies;
  const [displayCompanyId, setDisplayCompanyId] = useState<number>();
  const [displayCompany, setDisplayCompany] =
    useState<CompanyBasicInfoWithClientDto>();
  const [displayClientId, setDisplayClientId] = useState<number>();
  const [displayClient, setDisplayClient] = useState<ClientBasicInfoDto>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [popId, setPopId] = useState(0);
  const [openAdd, setOpenAdd] = useBoolean();
  const [openAddClient, setOpenAddClient] = useBoolean();
  const [search, setSearch] = useState("");
  const [clientModalContent, setClientModalContent] = useState<JSX.Element>();
  const [deleteClient, { isLoading: deleteClientLoading }] =
    useDeleteClientMutation();
  const [deletePopVis, setDeletePopVis] = useState(false);

  useEffect(() => {
    if (companyList && companyList.length > 0) {
      setDisplayCompanyId((!isSmilie ? userInfoState.userInfo.companyId : companyList[0].companyId));
    }
  }, [companyList === undefined]);

  useEffect(() => {
    if (
      displayCompanyId &&
      companyList &&
      companyList.find((i) => i.companyId === displayCompanyId)
    ) {
      setDisplayCompany(
        companyList.find((i) => i.companyId === displayCompanyId)
      );
    }
  }, [displayCompanyId, data]);

  useEffect(() => {
    if (displayCompany?.clients && displayCompany?.clients.length > 0) {
      setDisplayClientId(displayCompany?.clients[0].clientId);
    }
  }, [displayCompany?.clients === undefined]);

  useEffect(() => {
    if (
      displayClientId &&
      displayCompany?.clients &&
      displayCompany?.clients.find((i) => i.clientId === displayClientId)
    ) {
      setDisplayClient(
        displayCompany?.clients.find((i) => i.clientId === displayClientId)
      );
    }
    else if (displayClientId === undefined && displayCompany?.clients && displayCompany?.clients.length > 0) {
      setDisplayClient(displayCompany?.clients[0]);
    }

  }, [displayClientId, displayCompany?.clients]);

  const changeDisplayCompany = (id: number) => {
    if (data?.companies) {
      setDisplayCompanyId(id);
      let selectedCompany = data?.companies.find((s) => s.companyId === id);
      setDisplayCompany(selectedCompany);

      if (selectedCompany?.clients && selectedCompany?.clients.length > 0) {
        setDisplayClientId(selectedCompany?.clients[0].clientId);
        setDisplayClient(
          selectedCompany?.clients.find((i) => i.clientId === selectedCompany?.clients[0].clientId)
        );
      } else {
        setDisplayClientId(undefined);
        setDisplayClient(undefined);
      }
    }
  };

  const changeDisplayClientId = (id: number) => {
    setDisplayClientId(id);
  };

  const changeDisplayClient = (id: number) => {
    if (displayCompany?.clients) {
      setDisplayClientId(id);
      setDisplayClient(displayCompany?.clients.find((c) => c.clientId === id));
    }
  };

  const openAddClientModal = () => {
    setOpenAddClient.on();
    setClientModalContent(
      <ClientModalContent
        openModal={setOpenAddClient}
        changeDisplayId={changeDisplayClientId}
        companyId={displayCompany?.companyId}
        clientId={displayClient?.clientId}
      />
    );
  };

  const openEditClientModal = () => {
    setOpenAddClient.on();
    setClientModalContent(
      <ClientModalContent
        openModal={setOpenAddClient}
        editMode={true}
        companyId={displayCompany?.companyId}
        clientId={displayClient?.clientId}
        clientName={displayClient?.name}
      />
    );
  };

  const handleDeleteClient = async () => {
    if (!displayClient?.clientId) {
      return;
    }

    const result = await deleteClient({
      companyId: displayCompany?.companyId,
      clientId: displayClient.clientId,
    });
    if ("error" in result) {
      return;
    }

    if (displayCompany?.clients) {
      if (displayClient.clientId === displayCompany?.clients[0].clientId) {
        setDisplayClientId(
          displayCompany?.clients.length > 1
            ? displayCompany?.clients[1].clientId
            : -1
        );
      } else {
        setDisplayClientId(displayCompany?.clients[0].clientId);
      }
    }

    successToast("Client Delete Success!");
    setDeletePopVis(false);
  };

  return (
    <>
      <Flex direction={"column"} pt={"20px"}>
        <Flex>
          <Text
            fontWeight={"bold"}
            minW={"120px"}
            ml={"10px"}
            h={"40px"}
            lineHeight={"40px"}
          >
            Company
          </Text>
          <Select
            w={"fit-content"}
            minW={"200px"}
            ml={"20px"}
            value={!isSmilie ? userInfoState.userInfo.companyId : displayCompany?.companyId}
            onChange={(e) => changeDisplayCompany(_.toNumber(e.target.value))}
            isDisabled={!isSmilie}
          >
            {companyList?.map((c) => (
              <option key={c.companyId} value={c.companyId}>
                {c.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex mt={"10px"}>
          <Text
            fontWeight={"bold"}
            minW={"120px"}
            ml={"10px"}
            h={"40px"}
            lineHeight={"40px"}
          >
            Client
          </Text>
          <Select
            w={"fit-content"}
            minW={"200px"}
            ml={"20px"}
            value={displayClient?.clientId}
            onChange={(e) => changeDisplayClient(_.toNumber(e.target.value))}
          >
            {displayCompany?.clients?.map((s) => (
              <option key={s.clientId} value={s.clientId}>
                {s.name}
              </option>
            ))}
          </Select>
          <IconButton
            colorScheme={"green"}
            icon={<AddIcon />}
            ml={"20px"}
            onClick={() => openAddClientModal()}
            aria-label={"add"}
          />
          <IconButton
            icon={<EditIcon />}
            ml={"20px"}
            colorScheme={"blue"}
            onClick={() => openEditClientModal()}
            aria-label={"edit"}
          />
          <ConfirmPopover
            isOpen={deletePopVis}
            onClose={() => setDeletePopVis(false)}
            onOpen={() => setDeletePopVis(true)}
            confirmText={`Are you sure to delete client ${displayClient?.name}?`}
            onConfirm={handleDeleteClient}
            triggerButton={
              <IconButton
                colorScheme={"red"}
                icon={<DeleteIcon />}
                bg={"red"}
                ml={"20px"}
                aria-label={"delete"}
              />
            }
          />
          <Button
            onClick={setOpenAdd.on}
            colorScheme={"green"}
            w={"150px"}
            ml={"20px"}
          >
            Add Recipient
          </Button>
          <InputGroup width={"500px"} ml={"30px"}>
            <InputLeftElement children={<SearchIcon />} />
            <Input
              value={search}
              placeholder={"Search"}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search.length > 0 && (
              <InputRightElement
                children={
                  <CloseIcon
                    cursor={"pointer"}
                    fontSize={"10px"}
                    onClick={() => setSearch("")}
                  />
                }
              />
            )}
          </InputGroup>
        </Flex>
        <Wrap p={"20px"} spacing={"20px"}>
          {displayClient?.recipients
            ?.filter((item) => {
              const searchIgnoreCase = search.toLowerCase();
              return (
                item.email.toLowerCase().includes(searchIgnoreCase) ||
                item.firstName.toLowerCase().includes(searchIgnoreCase) ||
                displayClient.name.toLowerCase().includes(searchIgnoreCase)
              );
            })
            .map((b) => (
              <Popover
                key={b.clientRecipientId}
                onOpen={onOpen}
                isOpen={isOpen && popId === b.clientRecipientId}
                onClose={onClose}
              >
                <PopoverTrigger>
                  <WrapItem
                    key={b.clientRecipientId}
                    onClick={() => setPopId(b.clientRecipientId)}
                  >
                    <ClientRecipientDetailCell
                      key={b.clientRecipientId}
                      clientRecipientId={b.clientRecipientId}
                      email={b.email}
                      firstName={b.firstName}
                      lastName={b.lastName}
                      dob={b.dob}
                      genderId={b.genderId}
                      phoneNumber={b.phoneNumber}
                      countryId={b.countryId}
                      occupation={b.occupation}
                    />
                  </WrapItem>
                </PopoverTrigger>
                <PopoverContent minH={"400px"} minW={"400px"}>
                  <PopoverCloseButton />
                  <ClientRecipientInfoPopContent
                    companyId={displayCompany !== undefined ? displayCompany.companyId : 0}
                    clientId={displayClient?.clientId}
                    clientRecipientId={b.clientRecipientId}
                    email={b.email}
                    firstName={b.firstName}
                    lastName={b.lastName}
                    occupation={b.occupation}
                    phoneNumber={b.phoneNumber}
                    genderId={b.genderId}
                    countryId={b.countryId}
                    addressLineOne={b.addresses[0].addressLineOne}
                    addressLineTwo={b.addresses[0].addressLineTwo}
                    postalCode={b.addresses[0].postalCode}
                  />
                </PopoverContent>
              </Popover>
            ))}
        </Wrap>
      </Flex>
      <Modal isOpen={openAdd} onClose={setOpenAdd.off}>
        <ClientRecipientAddContent
          setAddOpen={setOpenAdd}
          companyId={displayCompany?.companyId}
          clientId={displayClient?.clientId}
        />
      </Modal>
      <Modal isOpen={openAddClient} onClose={setOpenAddClient.off}>
        {clientModalContent}
      </Modal>
    </>
  );
};

export default ClientBoardPage;
