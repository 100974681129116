import { FileUploadModel } from "../../../../types/FileUploadModel";
import { GiftingResponse } from "../../../../types/GiftingResponse";

export interface GiftsDto extends GiftingResponse {
  gifts: GiftDto[];
}

export type GiftDto = {
  giftId: number;
  name: string;
  price: number;
  giftSubCategoryId: number;
  giftSubCategoryName: string;
  description?: string;
  otherDetails?: string;
  location?: string;
  deliveryOption?: string;
  imagePath?: string;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface GiftCreateRequest {
  giftSubCategoryId: number;
  name: string;
  price: number | undefined;
  description?: string;
  otherDetails?: string;
  location?: string;
  deliveryOption?: string;
  imagePath?: string;
  file: FileUploadModel | null;
}

export interface GiftUpdateRequest extends GiftCreateRequest {
  giftId: number;
}

export interface GiftCreateResponse extends GiftingResponse {
  giftId: number;
}

export enum GiftTrackingStatus {
  Pending = 1,
  Ongoing = 2,
  Sent = 3,
  Received = 4
}

export enum GiftPickUpOption {
  SelfPickUp = 1,
  Delivery = 2
}