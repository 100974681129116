import { ChangeEvent, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FileUploadModel } from "../../../../types/FileUploadModel";
import { convertToBase64 } from "../../../../utils/FileUploadUtil";
import {
  useCreateGiftMutation,
  useGetGiftSubCategoryDropDownListQuery,
  useUpdateGiftMutation,
} from "../../api/GiftApiSlice";
import _ from "lodash";
import { successToast } from "../../../../utils/ToastUtil";

type GiftModalContentProps = {
  editMode?: boolean;
  giftId?: number;
  name?: string;
  price?: number;
  giftSubCategoryId?: number;
  description?: string;
  otherDetails?: string;
  location?: string;
  deliveryOption?: string;
  imagePath?: string;
  openModal: { on: () => void; off: () => void; toggle: () => void };
};

const GiftModalContent = (props: GiftModalContentProps) => {
  const {
    editMode = false,
    giftId,
    name,
    price,
    giftSubCategoryId,
    description,
    otherDetails,
    location,
    deliveryOption,
    imagePath,
    openModal,
  } = props;
  const { data: giftSubCategoriesApiData } =
    useGetGiftSubCategoryDropDownListQuery();
  const [nameValue, setNameValue] = useState<string>(name || "");
  const [priceValue, setPriceValue] = useState<number>(
    price || 0
  );
  const [giftSubCategoryIdValue, setGiftSubCategoryIdValue] = useState<number>(
    giftSubCategoryId || 0
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(
    description || ""
  );
  const [otherDetailsValue, setOtherDetailsValue] = useState<string>(
    otherDetails || ""
  );
  const [locationValue, setLocationValue] = useState<string>(
    location || ""
  );
  const [deliveryOptionValue, setDeliveryOptionValue] = useState<string>(
    deliveryOption || ""
  );
  const [isError, setIsError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [createGift, { isLoading: submitLoading }] = useCreateGiftMutation();
  const [updateGift, { isLoading: updateLoading }] = useUpdateGiftMutation();
  const toast = useToast();

  const validatePrice = () => {
    return _.isEmpty(_.toString(priceValue)) || priceValue === 0 || isNaN(priceValue);
  };

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e != null && e.target.files != null && e.target.files?.length > 0) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const submitGift = async () => {
    if (nameValue === "" || validatePrice() || giftSubCategoryIdValue === 0) {
      setIsError(true);
      return;
    }

    let fileUploadModel: FileUploadModel = { fileName: "", base64String: "" };
    if (uploadedFile != null) {
      fileUploadModel.fileName = uploadedFile.name;
      fileUploadModel.base64String = (await convertToBase64(
        uploadedFile
      )) as string;
      if (fileUploadModel.base64String) {
        fileUploadModel.base64String =
          fileUploadModel.base64String.split(";base64,")[1];
      }
    }

    let result;
    if (editMode) {
      result = await updateGift({
        giftId: giftId || 0,
        name: nameValue,
        price: priceValue,
        giftSubCategoryId: giftSubCategoryIdValue || 0,
        description: descriptionValue,
        otherDetails: otherDetailsValue,
        location: locationValue,
        deliveryOption: deliveryOptionValue,
        file: fileUploadModel,
      });
    } else {
      result = await createGift({
        name: nameValue,
        price: priceValue,
        giftSubCategoryId: giftSubCategoryIdValue || 0,
        description: descriptionValue,
        otherDetails: otherDetailsValue,
        location: locationValue,
        deliveryOption: deliveryOptionValue,
        file: fileUploadModel,
      });
    }

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast(`Gift ${editMode ? "Update" : "Add"} Success!`);

    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editMode ? "Edit Gift" : "Add Gift"}</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl isRequired isInvalid={isError && nameValue === ""}>
            <FormLabel>Gift Name</FormLabel>
            <Input
              placeholder={"Gift Name"}
              value={nameValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setNameValue(e.target.value);
              }}
            />
            {isError && nameValue === "" && (
              <FormErrorMessage>Gift Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4" isRequired isInvalid={isError && validatePrice()}>
            <FormLabel>Price</FormLabel>
            <NumberInput
              defaultValue={priceValue}
              precision={2}
              step={1}
              isInvalid={isError && validatePrice()}
              onChange={(valueString) => {
                if (validatePrice()) {
                  setIsError(true);
                }
                setPriceValue(parseFloat(valueString));
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {isError && validatePrice() && (
              <FormErrorMessage>Price is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            mt="4"
            isRequired
            isInvalid={isError && giftSubCategoryIdValue === 0}
          >
            <FormLabel>Gift Sub Category</FormLabel>
            <Select
              onChange={(e) =>
                setGiftSubCategoryIdValue(parseInt(e.target.value))
              }
              value={giftSubCategoryIdValue}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {giftSubCategoriesApiData?.success
                ? giftSubCategoriesApiData?.giftSubCategories.map(
                    (category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  )
                : ""}
            </Select>
            {isError && giftSubCategoryIdValue === 0 && (
              <FormErrorMessage>
                Gift Sub Category is required.
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Description</FormLabel>
            <Textarea
              value={descriptionValue}
              resize={"none"}
              placeholder={"Description"}
              onChange={(e) => setDescriptionValue(e.target.value)}
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Other Details</FormLabel>
            <Textarea
              value={otherDetailsValue}
              resize={"none"}
              placeholder={"Other Details"}
              onChange={(e) => setOtherDetailsValue(e.target.value)}
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Location</FormLabel>
            <Textarea
              value={locationValue}
              resize={"none"}
              placeholder={"Location"}
              onChange={(e) => setLocationValue(e.target.value)}
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Delivery Options</FormLabel>
            <Textarea
              value={deliveryOptionValue}
              resize={"none"}
              placeholder={"Delivery Options"}
              onChange={(e) => setDeliveryOptionValue(e.target.value)}
            />
          </FormControl>
          <FormControl mt="4" hidden={!editMode || !imagePath}>
            <FormLabel>Current Image</FormLabel>
            <Avatar size={"2xl"} name="Image" src={imagePath}></Avatar>
          </FormControl>
          <FormControl mt="4">
            <FormLabel>
              {editMode && imagePath ? "Upload New Image" : "Upload Image"}
            </FormLabel>
            <Input type="file" onChange={saveFile} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitGift}
            colorScheme="blue"
            isLoading={editMode ? updateLoading : submitLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default GiftModalContent;
