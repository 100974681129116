import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
} from "@chakra-ui/react";
import {
  useCreateCampaignMutation,
  useGetCampaignTypesQuery,
  useUpdateCampaignMutation,
} from "../../api/CampaignApiSlice";
import _ from "lodash";
import { successToast } from "../../../../utils/ToastUtil";
import { useGetCompanyDropDownListQuery } from "../../../companies/api/CompanyApiSlice";
import moment from "moment";
import {
  CampaignCreateRequest,
  CampaignUpdateRequest,
} from "../../api/dto/CampaignDto";
import { useGetGiftSubCategoryDropDownListQuery } from "../../../gifts/api/GiftApiSlice";
import { MultiSelect } from "react-multi-select-component";
import { useGetClientRecipientDropDownListQuery } from "../../../clients/api/ClientApiSlice";
import { getUserInfoState } from "../../../authentication/AuthSlice";
import { useAppSelector } from "../../../../components/common/Hooks";
import { SmilieCompanyId } from "../../../../constants/ValueConstant";

type CampaignModalContentProps = {
  editMode?: boolean;
  campaign?: CampaignUpdateRequest;
  giftSubCategoryIds?: number[];
  recipientIds?: number[];
  openModal: { on: () => void; off: () => void; toggle: () => void };
};

const CampaignModalContent = (props: CampaignModalContentProps) => {
  const userInfoState = useAppSelector(getUserInfoState);
  const isSmilie: boolean = userInfoState.userInfo.companyId === SmilieCompanyId;
  const {
    editMode = false,
    campaign,
    giftSubCategoryIds,
    recipientIds,
    openModal,
  } = props;
  const [companyIdValue, setCompanyIdValue] = useState<number>(
    campaign?.companyId || (!isSmilie ? userInfoState.userInfo.companyId : 0)
  );
  const [nameValue, setNameValue] = useState<string>(campaign?.name || "");
  const [descriptionValue, setDescriptionValue] = useState<string | undefined>(
    campaign?.description || undefined
  );
  const [budgetValue, setBudgetValue] = useState<number>(
    campaign?.budget || 0
  );
  const [campaignTypeIdValue, setCampaignTypeIdValue] = useState<number>(
    campaign?.campaignTypeId || 0
  );
  const [recipientTypeValue, setRecipientTypeValue] = useState<number>(
    campaign?.recipientType || 0
  );
  const [startDateValue, setStartDateValue] = useState<Date>(
    campaign?.startDate || new Date()
  );
  const [defaultMessageToGiftRecipientValue, setDefaultMessageToGiftRecipientValue] = useState<string | undefined>(
    campaign?.defaultMessageToGiftRecipient || undefined
  );
  const [giftSubCategoryValues, setGiftSubCategoryValues] = useState<
    { label: string; value: number }[]
  >([]);
  const [recipientValues, setRecipientValues] = useState<
    { label: string; value: number }[]
  >([]);
  const [isError, setIsError] = useState(false);
  const { data: companiesApiData } = useGetCompanyDropDownListQuery();
  const { data: campaignTypesApiData } = useGetCampaignTypesQuery();
  const { data: giftSubCategoriesApiData } =
    useGetGiftSubCategoryDropDownListQuery();
  const { data: clientRecipientsApiData } =
    useGetClientRecipientDropDownListQuery();
  const [createCampaign, { isLoading: createLoading }] =
    useCreateCampaignMutation();
  const [updateCampaign, { isLoading: updateLoading }] =
    useUpdateCampaignMutation();

  let giftSubCategoryOptions = giftSubCategoriesApiData?.success
    ? giftSubCategoriesApiData.giftSubCategories.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory.id,
      }))
    : [];

  let clientRecipientOptions = clientRecipientsApiData?.success
    ? clientRecipientsApiData.clientRecipients
        .filter(
          (clientRecipient) => clientRecipient.companyId == companyIdValue
        )
        .map((clientRecipient) => ({
          label: clientRecipient.name,
          value: clientRecipient.id,
        }))
    : [];

  useEffect(() => {
    if (
      giftSubCategoryIds !== undefined &&
      giftSubCategoryIds.length > 0 &&
      giftSubCategoryOptions.length > 0
    ) {
      let selectedValues = giftSubCategoryOptions.filter((option) =>
        giftSubCategoryIds.includes(option.value)
      );
      if (selectedValues.length > 0) setGiftSubCategoryValues(selectedValues);
    }
  }, [giftSubCategoryOptions.length === 0]);

  useEffect(() => {
    if (
      recipientIds !== undefined &&
      recipientIds.length > 0 &&
      clientRecipientOptions.length > 0
    ) {
      let selectedValues = clientRecipientOptions.filter((option) =>
        recipientIds.includes(option.value)
      );
      setRecipientValues(selectedValues);
    }
  }, [clientRecipientOptions.length === 0]);

  const validateBudget = () => {
    return _.isEmpty(_.toString(budgetValue)) || budgetValue === 0 || isNaN(budgetValue);
  };

  const handleChangeCompany = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompanyIdValue(parseInt(e.target.value));
    setRecipientValues([]);
  };

  const submitCampaign = async () => {
    if (
      companyIdValue === 0 ||
      nameValue === "" ||
      budgetValue === undefined ||
      budgetValue === 0 ||
      campaignTypeIdValue === 0 ||
      recipientTypeValue === 0 ||
      giftSubCategoryValues.length === 0 ||
      recipientValues.length === 0
    ) {
      setIsError(true);
      return;
    }

    let result;
    if (editMode) {
      let request: CampaignUpdateRequest = {
        campaignId: campaign?.campaignId,
        companyId: companyIdValue,
        name: nameValue,
        description: descriptionValue,
        budget: budgetValue,
        campaignTypeId: campaignTypeIdValue,
        recipientType: recipientTypeValue,
        startDate: startDateValue,
        defaultMessageToGiftRecipient: defaultMessageToGiftRecipientValue,
        giftSubCategories: giftSubCategoryValues,
        recipients: recipientValues,
      };

      result = await updateCampaign(request);
    } else {
      let request: CampaignCreateRequest = {
        companyId: companyIdValue,
        name: nameValue,
        description: descriptionValue,
        budget: budgetValue,
        campaignTypeId: campaignTypeIdValue,
        recipientType: recipientTypeValue,
        startDate: startDateValue,
        defaultMessageToGiftRecipient: defaultMessageToGiftRecipientValue,
        giftSubCategories: giftSubCategoryValues,
        recipients: recipientValues,
      };

      result = await createCampaign(request);
    }

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast(`Campaign ${editMode ? "Update" : "Add"} Success!`);

    openModal.off();
  };

  return (
    <ModalContent top={"0%"} maxW={"unset"} w={"800px"}>
      <ModalHeader>{editMode ? "Edit Campaign" : "Add Campaign"}</ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody px={"40px"}>
        <Grid
          rowGap={"10px"}
          columnGap={"10%"}
          templateColumns={"repeat(2, 1fr)"}
        >
          <FormControl isRequired isInvalid={isError && companyIdValue === 0}>
            <FormLabel>Company</FormLabel>
            <Select
              onChange={(e) => handleChangeCompany(e)}
              value={!isSmilie ? userInfoState.userInfo.companyId : companyIdValue}
              isDisabled={!isSmilie}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {companiesApiData?.success
                ? companiesApiData?.companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))
                : ""}
            </Select>
            {isError && companyIdValue === 0 && (
              <FormErrorMessage>Company is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isError && nameValue === ""}>
            <FormLabel>Campaign Name</FormLabel>
            <Input
              placeholder={"Campaign Name"}
              value={nameValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setNameValue(e.target.value);
              }}
            />
            {isError && nameValue === "" && (
              <FormErrorMessage>Campaign Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && campaignTypeIdValue === 0}
          >
            <FormLabel>Campaign Type</FormLabel>
            <Select
              onChange={(e) => setCampaignTypeIdValue(parseInt(e.target.value))}
              value={campaignTypeIdValue}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {campaignTypesApiData?.success
                ? campaignTypesApiData?.campaignTypes.map((campaignType) => (
                    <option
                      key={campaignType.campaignTypeId}
                      value={campaignType.campaignTypeId}
                    >
                      {campaignType.name}
                    </option>
                  ))
                : ""}
            </Select>
            {isError && campaignTypeIdValue === 0 && (
              <FormErrorMessage>Campaign Type is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && recipientTypeValue === 0}
          >
            <FormLabel>Recipient Type</FormLabel>
            <Select
              onChange={(e) => setRecipientTypeValue(parseInt(e.target.value))}
              value={recipientTypeValue}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {/* <option key={1} value={1}>
                Internal
              </option> */}
              <option key={2} value={2}>
                External
              </option>
            </Select>
            {isError && recipientTypeValue === 0 && (
              <FormErrorMessage>Recipient Type is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isError && validateBudget()}>
            <FormLabel>Budget</FormLabel>
            <NumberInput
              defaultValue={budgetValue}
              precision={2}
              step={1}
              isInvalid={isError && validateBudget()}
              onChange={(valueString) => {
                if (validateBudget()) {
                  setIsError(true);
                }
                setBudgetValue(parseFloat(valueString));
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {isError && validateBudget() && (
              <FormErrorMessage>Budget is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              value={moment(startDateValue).format("YYYY-MM-DD")}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setStartDateValue(new Date(e.target.value));
              }}
            />
            {isError && (
              <FormErrorMessage>Start Date is required.</FormErrorMessage>
            )}
          </FormControl>
          <GridItem colSpan={2}>
            <FormControl
              isRequired
              isInvalid={isError && giftSubCategoryValues.length === 0}
            >
              <FormLabel>Gift Sub Categories</FormLabel>
              <MultiSelect
                options={giftSubCategoryOptions}
                value={giftSubCategoryValues}
                onChange={setGiftSubCategoryValues}
                labelledBy={"Please select"}
              />
              {isError && giftSubCategoryValues.length === 0 && (
                <FormErrorMessage>
                  Gift Sub Categories is required.
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl
              isRequired
              isInvalid={isError && recipientValues.length === 0}
            >
              <FormLabel>Recipients</FormLabel>
              <MultiSelect
                options={clientRecipientOptions}
                value={recipientValues}
                onChange={setRecipientValues}
                labelledBy={"Please select"}
              />
              {isError && recipientValues.length === 0 && (
                <FormErrorMessage>Recipients is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                rows={4}
                value={descriptionValue}
                resize={"none"}
                placeholder={"Description"}
                onChange={(e) => setDescriptionValue(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>{editMode ? "Default Message to New Recipient" : "Default Message to Recipient"}</FormLabel>
              <Textarea
                rows={4}
                value={defaultMessageToGiftRecipientValue}
                resize={"none"}
                placeholder={"Default message"}
                onChange={(e) => setDefaultMessageToGiftRecipientValue(e.target.value)}
              />
            </FormControl>
          </GridItem>
          
        </Grid>
      </ModalBody>
      <ModalFooter justifyContent={"center"} gap={"10%"} mt={"10px"}>
        <Button
          onClick={submitCampaign}
          colorScheme="blue"
          isLoading={editMode ? updateLoading : createLoading}
          mr="3"
        >
          Save
        </Button>
        <Button variant="ghost" onClick={() => openModal.off()}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default CampaignModalContent;
