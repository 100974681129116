import { Navigate, RouteObject } from "react-router-dom";
import CampaignListPage from "../pages/campaigns/CampaignList";
import ChangePasswordPage from "../pages/ChangePassword";
import ClientBoardPage from "../pages/clients/ClientBoard";
import DashboardPage from "../pages/Dashboard";
import GiftRecipientListPage from "../pages/gift-recipients/GiftRecipientList";
import GiftListPage from "../pages/gifts/GiftList";
import ProfilePage from "../pages/Profile";

export const clientUserRoutes: RouteObject[] = [
  {
    path: "Dashboard",
    element: <DashboardPage />,
  },
  {
    path: "Clients",
    children: [
      {
        index: true,
        element: <Navigate to={"Clients"} />,
      },
      {
        path: "Clients",
        element: <ClientBoardPage />,
      },
    ],
  },
  {
    path: "Campaigns",
    children: [
      {
        index: true,
        element: <Navigate to={"CampaignList"} />,
      },
      {
        path: "CampaignList",
        element: <CampaignListPage />,
      },
    ],
  },
  {
    path: "GiftRecipients",
    children: [
      {
        index: true,
        element: <Navigate to={"GiftRecipients"} />,
      },
      {
        path: "GiftRecipients",
        element: <GiftRecipientListPage />,
      },
    ],
  },
  {
    path: "Settings",
    children: [
      {
        index: true,
        element: <Navigate to={"Profile"} />,
      },
      {
        path: "Profile",
        element: <ProfilePage />,
      },
      {
        path: "ChangePassword",
        element: <ChangePasswordPage />,
      },
    ],
  },
];