import { PulseLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      style={{ position: "absolute", left: "50%", top: "40%" }}
    />
  )
}

export default LoadingSpinner;
