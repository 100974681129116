import { useEffect, useState } from "react";
import {
  Button,
  Text,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  useBoolean,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import _ from "lodash";
import { useDeleteGiftCategoryMutation, useGetGiftCategoriesWithSubCategoriesQuery } from "../../features/gifts/api/GiftApiSlice";
import { GiftCategoryWithSubCategoryDto } from "../../features/gifts/api/dto/GiftCategoryDto";
import GiftCategoryModalContent from "../../features/gifts/components/modal/GiftCategoryModalContent";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import GiftSubCategoryDetailCell from "../../features/gifts/components/GiftSubCategoryDetailCell";
import GiftSubCategoryAddContent from "../../features/gifts/components/GiftSubCategoryAddContent";
import GiftSubCategoryInfoPopContent from "../../features/gifts/components/GiftSubCategoryInfoPopContent";
import { successToast } from "../../utils/ToastUtil";

const GiftCategoryBoardPage = () => {
  const { data } = useGetGiftCategoriesWithSubCategoriesQuery();
  const giftCategoryList = data?.giftCategories;

  const [displayId, setDisplayId] = useState<number>();
  const [displayGiftCategory, setDisplayGiftCategory] = useState<GiftCategoryWithSubCategoryDto>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [popId, setPopId] = useState(0);
  const [openAdd, setOpenAdd] = useBoolean();
  const [openAddGiftCategory, setOpenAddGiftCategory] = useBoolean();
  const [search, setSearch] = useState("");
  const [giftCategoryModalContent, setGiftCategoryModalContent] = useState<JSX.Element>();
  const [deleteGiftCategory, { isLoading: deleteLoading }] = useDeleteGiftCategoryMutation();
  const [deletePopVis, setDeletePopVis] = useState(false);

  useEffect(() => {
    if (
      displayId &&
      giftCategoryList &&
      giftCategoryList.find((i) => i.giftCategoryId === displayId)
    ) {
      setDisplayGiftCategory(giftCategoryList.find((i) => i.giftCategoryId === displayId));
    }
  }, [displayId, data]);

  useEffect(() => {
    if (giftCategoryList && giftCategoryList.length > 0) {
      setDisplayId(giftCategoryList[0].giftCategoryId);
    }
  }, [giftCategoryList === undefined]);

  const changeDisplayId = (id: number) => {
    setDisplayId(id);
  };

  const changeDisplayGiftCategory = (id: number) => {
    if (data?.giftCategories) {
      setDisplayId(id);
      setDisplayGiftCategory(data?.giftCategories.find((s) => s.giftCategoryId === id));
    }
  };

  const openAddGiftCategoryModal = () => {
    setOpenAddGiftCategory.on();
    setGiftCategoryModalContent(
      <GiftCategoryModalContent
        openModal={setOpenAddGiftCategory}
        changeDisplayId={changeDisplayId}
        giftCategoryId={displayGiftCategory?.giftCategoryId}
      />
    );
  };

  const openEditGiftCategoryModal = () => {
    setOpenAddGiftCategory.on();
    setGiftCategoryModalContent(
      <GiftCategoryModalContent
        openModal={setOpenAddGiftCategory}
        editMode={true}
        defaultValue={displayGiftCategory?.name}
        giftCategoryId={displayGiftCategory?.giftCategoryId}
        imagePath={displayGiftCategory?.imagePath}
      />
    );
  };

  const handleDeleteGiftCategory = async () => {
    if (!displayGiftCategory?.giftCategoryId) {
      return;
    }
    
    const result = await deleteGiftCategory(displayGiftCategory.giftCategoryId);
    if ("error" in result) {
      return;
    }

    if (giftCategoryList) {
      if (displayGiftCategory.giftCategoryId === giftCategoryList[0].giftCategoryId) {
        setDisplayId(giftCategoryList.length > 1 ? giftCategoryList[1].giftCategoryId : -1);
      } else {
        setDisplayId(giftCategoryList[0].giftCategoryId);
      }
    }

    successToast("Gift Category Delete Success!");

    setDeletePopVis(false);
  };

  return (
    <>
      <Flex direction={"column"} pt={"20px"}>
        <Flex>
          <Text fontWeight={"bold"} minW={"120px"} ml={"10px"} h={"40px"} lineHeight={"40px"}>
            Gift Category
          </Text>
          <Select
            w={"fit-content"}
            minW={"200px"}
            ml={"20px"}
            value={displayGiftCategory?.giftCategoryId}
            onChange={(e) => changeDisplayGiftCategory(_.toNumber(e.target.value))}
          >
            {giftCategoryList?.map((s) => (
              <option key={s.giftCategoryId} value={s.giftCategoryId}>
                {s.name}
              </option>
            ))}
          </Select>
          <IconButton
            colorScheme={"green"}
            icon={<AddIcon />}
            ml={"20px"}
            onClick={() => openAddGiftCategoryModal()}
            aria-label={"add"}
          />
          <IconButton
            icon={<EditIcon />}
            ml={"20px"}
            colorScheme={"blue"}
            onClick={() => openEditGiftCategoryModal()}
            aria-label={"edit"}
          />
          <ConfirmPopover
            isOpen={deletePopVis}
            onClose={() => setDeletePopVis(false)}
            onOpen={() => setDeletePopVis(true)}
            confirmText={`Are you sure to delete category ${displayGiftCategory?.name}?`}
            onConfirm={handleDeleteGiftCategory}
            triggerButton={
              <IconButton
                colorScheme={"red"}
                icon={<DeleteIcon />}
                bg={"red"}
                ml={"20px"}
                aria-label={"delete"}
              />
            }
          />
          <Button
            onClick={setOpenAdd.on}
            colorScheme={"green"}
            w={"150px"}
            ml={"20px"}
          >
            Add Sub Category
          </Button>
          <InputGroup width={"500px"} ml={"30px"}>
            <InputLeftElement children={<SearchIcon />} />
            <Input
              value={search}
              placeholder={"Search"}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search.length > 0 && (
              <InputRightElement
                children={
                  <CloseIcon
                    cursor={"pointer"}
                    fontSize={"10px"}
                    onClick={() => setSearch("")}
                  />
                }
              />
            )}
          </InputGroup>
        </Flex>
        <Wrap p={"20px"} spacing={"20px"}>
          {displayGiftCategory?.subCategories
            ?.filter((item) => {
              const searchIgnoreCase = search.toLowerCase();
              return (
                item.name.toLowerCase().includes(searchIgnoreCase) ||
                displayGiftCategory.name.toLowerCase().includes(searchIgnoreCase)
              );
            })
            .map((b) => (
              <Popover
                key={b.giftSubCategoryId}
                onOpen={onOpen}
                isOpen={isOpen && popId === b.giftSubCategoryId}
                onClose={onClose}
              >
                <PopoverTrigger>
                  <WrapItem
                    key={b.giftSubCategoryId}
                    onClick={() => setPopId(b.giftSubCategoryId)}
                  >
                    <GiftSubCategoryDetailCell
                      key={b.giftSubCategoryId}
                      name={b.name}
                      giftCategory={displayGiftCategory?.name}
                      imagePath={b.imagePath}
                    />
                  </WrapItem>
                </PopoverTrigger>
                <PopoverContent minH={"400px"}>
                  <PopoverCloseButton />
                  <GiftSubCategoryInfoPopContent
                    giftSubCategoryId={b.giftSubCategoryId}
                    name={b.name}
                    giftCategoryId={displayGiftCategory?.giftCategoryId}
                    giftCategory={displayGiftCategory?.name}
                    isForOnboarding={b.isForOnboarding}
                    imagePath={b.imagePath}
                    file={null}
                  />
                </PopoverContent>
              </Popover>
            ))}
        </Wrap>
      </Flex>
      <Modal isOpen={openAdd} onClose={setOpenAdd.off}>
        <GiftSubCategoryAddContent
          setAddOpen={setOpenAdd}
          giftCategoryId={displayGiftCategory?.giftCategoryId}
          isForOnboarding={true}
        />
      </Modal>
      <Modal isOpen={openAddGiftCategory} onClose={setOpenAddGiftCategory.off}>
        {giftCategoryModalContent}
      </Modal>
    </>
  );
};

export default GiftCategoryBoardPage;
