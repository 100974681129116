import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  useCreateClientMutation,
  useUpdateClientMutation,
} from "../../api/ClientApiSlice";
import { successToast } from "../../../../utils/ToastUtil";

type ClientModalContentProps = {
  editMode?: boolean;
  companyId?: number;
  clientId?: number;
  clientName?: string;
  openModal: { on: () => void; off: () => void; toggle: () => void };
  changeDisplayId?: (id: number) => void;
};

const ClientModalContent = (props: ClientModalContentProps) => {
  const {
    editMode = false,
    companyId,
    clientId,
    clientName,
    openModal,
  } = props;
  const [name, setName] = useState<string>(clientName || "");
  const [isError, setIsError] = useState(false);
  const [createClient, { isLoading: submitLoading }] =
    useCreateClientMutation();
  const [updateClient, { isLoading: updateLoading }] =
    useUpdateClientMutation();
  const toast = useToast();

  const submitClient = async () => {
    if (name === "") {
      setIsError(true);
      return;
    }

    let result;
    if (editMode) {
      result = await updateClient({
        companyId: companyId,
        clientId: clientId || 0,
        name,
      });
    } else {
      result = await createClient({
        companyId,
        name,
      });
    }

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast(`Client ${editMode ? "Update" : "Add"} Success!`);

    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editMode ? "Edit Client" : "Add Client"}</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl isRequired isInvalid={isError && name === ""}>
            <FormLabel>Client Name</FormLabel>
            <Input
              placeholder={"Client Name"}
              value={name}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setName(e.target.value);
              }}
            />
            {isError && name === "" && (
              <FormErrorMessage>Client Name is required.</FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitClient}
            colorScheme="blue"
            isLoading={editMode ? updateLoading : submitLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default ClientModalContent;
