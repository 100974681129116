import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import LoginPage, { checkTokenExpiry } from "../../pages/LoginPage";

export function authenticate() {
  const token = localStorage.getItem('token');
  return token && checkTokenExpiry() ? true : false;
}

function FooterLayout() {
  const { pathname } = useLocation();

  return (
    <Flex h='100%' flexDir='column' zIndex={999}>
      <Box flex='auto'>
        {authenticate() ? <Outlet /> : <LoginPage />}
      </Box>
      {/* Don't show footer on default page */}
      {pathname === "/" ? null : <Flex
        backgroundColor={'gray.100'}
        direction={{
          base: "column",
          xl: "row",
        }}
        justify='center'
        align={{
          base: "center",
          xl: "start"
        }}
        p='20px'>
        <Text color='gray.400'
          textAlign={{
            base: "center",
            xl: "start",
          }}
          mb={{ base: "20px", xl: "0px" }}>
          &copy; {new Date().getFullYear()}{" Powered by "}
          <Link color='teal.500' target='_blank' px='5px'
            href='https://smilie.io' isExternal>
            Smilie.
          </Link>
        </Text>
      </Flex>}
    </Flex>
  );
}

export default FooterLayout;