import { useRoutes } from "react-router-dom";
import _ from "lodash";
import { UserProfile } from "../../types/UserProfile";
import { useAppSelector } from "../common/Hooks";
import { activeRoutes, getRoutes } from "../../routes/Route";

const getActiveRoutes = (userInfo: UserProfile) => {
  if (!userInfo || !userInfo.roles) {
    return [];
  }

  const roleNames: string[] = userInfo?.roles?.map(({ name }) => name);
  return _.union(...roleNames.map((n) => activeRoutes.get(n)));
};

const RoutesLayout = () => {
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  return useRoutes(getRoutes(getActiveRoutes(userInfo)));
};

export default RoutesLayout;
