import { ChangeEvent, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { FileUploadModel } from "../../../../types/FileUploadModel";
import { convertToBase64 } from "../../../../utils/FileUploadUtil";
import { useCreateCompanyMutation, useUpdateCompanyMutation } from "../../api/CompanyApiSlice";
import { successToast } from "../../../../utils/ToastUtil";

type CompanyModalContentProps = {
  editMode?: boolean;
  defaultValue?: string;
  companyId?: number;
  logoImagePath?: string;
  openModal: { on: () => void; off: () => void; toggle: () => void };
};

const CompanyModalContent = (props: CompanyModalContentProps) => {
  const {
    editMode = false,
    defaultValue,
    companyId,
    logoImagePath,
    openModal
  } = props;
  const [name, setName] = useState<string>(defaultValue || "");
  const [isError, setIsError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [createCompany, { isLoading: submitLoading }] = useCreateCompanyMutation();
  const [updateCompany, { isLoading: updateLoading }] = useUpdateCompanyMutation();

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e != null && e.target.files != null && e.target.files?.length > 0) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const submitCompany = async () => {
    if (name === "") {
      setIsError(true);
      return;
    }

    let fileUploadModel: FileUploadModel = { fileName: '', base64String: '' };
    if (uploadedFile != null) {
      fileUploadModel.fileName = uploadedFile.name;
      fileUploadModel.base64String = await convertToBase64(uploadedFile) as string;
      if (fileUploadModel.base64String) {
        fileUploadModel.base64String = fileUploadModel.base64String.split(';base64,')[1]
      }
    }

    let result;
    if (editMode) {
      result = await updateCompany({
        companyId: companyId || 0,
        name,
        file: fileUploadModel
      });
    } else {
      result = await createCompany({
        name, file: fileUploadModel
      });
    }

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast(`Company ${editMode ? "Update" : "Add"} Success!`);
    
    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editMode ? "Edit Company" : "Add Company"}</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl isRequired isInvalid={isError && name === ""}>
            <FormLabel>Company Name</FormLabel>
            <Input
              placeholder={"Company Name"}
              value={name}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setName(e.target.value);
              }}
            />
            {isError && name === "" && (
              <FormErrorMessage>Company Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4" hidden={!editMode || !logoImagePath}>
            <FormLabel>Current Logo</FormLabel>
            <Avatar size={"2xl"} name="Image" src={logoImagePath}></Avatar>
          </FormControl>
          <FormControl mt="4">
            <FormLabel>{editMode && logoImagePath ? "Upload New Logo" : "Upload Logo"}</FormLabel>
            <Input 
              type="file"
              onChange={saveFile}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitCompany}
            colorScheme="blue"
            isLoading={editMode ? updateLoading : submitLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default CompanyModalContent;
