import {
  FiCalendar,
  FiGift,
  FiLayout,
  FiList,
  FiMail,
  FiSettings,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import _ from "lodash";
import { LinkItemProps } from "../../types/LinkItemProps";
import { CLIENT_ADMIN, CLIENT_USER, SUPER_ADMIN } from "../../constants/RoleConstant";
import { UserRole } from "../../types/UserRole";
import { FaRegBuilding } from "react-icons/fa";
import { IoMdTimer } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";

const baseNavItemHeader: Array<LinkItemProps> = [
  { name: "Dashboard", icon: FiLayout, pathname: "Dashboard" }
];

const baseNavItemFooter: Array<LinkItemProps> = [
  { name: "Settings", icon: FiSettings, pathname: "Settings", extendsParentPath: true,
    subItems: [
      // { name: "Profile", icon: FiUser, pathname: "Profile" },
      { name: "Change Password", icon: RiLockPasswordLine, pathname: "ChangePassword" }
    ] 
  }
];

const superAdminNavItem: Array<LinkItemProps> = [
  { name: "Companies", icon: FaRegBuilding, pathname: "Companies", extendsParentPath: true,
    subItems: [
      { name: "All Companies", icon: FiList, pathname: "CompanyList" },
      { name: "Manage Clients", icon: FiUsers, pathname: "Clients" }
    ] 
  },
  { name: "Gift Categories", icon: FiList, pathname: "GiftCategories", extendsParentPath: true,
    subItems: [
      { name: "All Categories", icon: FiList, pathname: "GiftMainCategories" },
      { name: "Manage", icon: FiList, pathname: "Manage" }
    ] 
  },
  { name: "Campaigns", icon: IoMdTimer, pathname: "Campaigns", extendsParentPath: true,
    subItems: [
      { name: "All Campaigns", icon: FiList, pathname: "CampaignList" }
    ] 
  },
  { name: "Gifts", icon: FiGift, pathname: "Gifts", extendsParentPath: true,
    subItems: [
      { name: "All Gifts", icon: FiList, pathname: "Gifts" },
      { name: "All Recipients", icon: FiList, pathname: "GiftRecipients" }
    ] 
  },
  { name: "Users", icon: FiUsers, pathname: "Users", extendsParentPath: true,
    subItems: [
      { name: "All Users", icon: FiList, pathname: "Users" },
    ] 
  },
];

const clientAdminNavItem: Array<LinkItemProps> = [
  { name: "Clients", icon: FaRegBuilding, pathname: "Clients", extendsParentPath: true,
    subItems: [
      { name: "Manage Clients", icon: FiUsers, pathname: "Clients" }
    ] 
  },
  { name: "Campaigns", icon: IoMdTimer, pathname: "Campaigns", extendsParentPath: true,
    subItems: [
      { name: "All Campaigns", icon: FiList, pathname: "CampaignList" }
    ] 
  },
  { name: "Gift Recipients", icon: FiGift, pathname: "GiftRecipients", extendsParentPath: true,
    subItems: [
      { name: "All Recipients", icon: FiList, pathname: "GiftRecipients" }
    ] 
  },
  { name: "Users", icon: FiUsers, pathname: "Users", extendsParentPath: true,
    subItems: [
      { name: "All Users", icon: FiList, pathname: "Users" },
    ] 
  },
];

const clientUserNavItem: Array<LinkItemProps> = [
  { name: "Clients", icon: FaRegBuilding, pathname: "Clients", extendsParentPath: true,
    subItems: [
      { name: "Manage Clients", icon: FiUsers, pathname: "Clients" }
    ] 
  },
  { name: "Campaigns", icon: IoMdTimer, pathname: "Campaigns", extendsParentPath: true,
    subItems: [
      { name: "All Campaigns", icon: FiList, pathname: "CampaignList" }
    ] 
  },
  { name: "Gift Recipients", icon: FiGift, pathname: "GiftRecipients", extendsParentPath: true,
    subItems: [
      { name: "All Recipients", icon: FiList, pathname: "GiftRecipients" }
    ] 
  },
];

export const navItemsMap: Map<string, LinkItemProps[]> = new Map([
  [SUPER_ADMIN, superAdminNavItem],
  [CLIENT_ADMIN, clientAdminNavItem],
  [CLIENT_USER, clientUserNavItem]
]);

export const getActiveNavItems = (roles: UserRole[]) => {
  if (!roles) {
      return;
  }
  const roleNames: string[] = roles.map(({ name }) => name);
  return _.union(baseNavItemHeader, ...roleNames.map((n) => navItemsMap.get(n)), baseNavItemFooter);
};
