import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { CampaignDto } from "./api/dto/CampaignDto";
import { CampaignTypeDto } from "./api/dto/CampaignTypeDto";

type CampaignState = {
  campaigns: CampaignDto[]
  campaignTypes: CampaignTypeDto[]
}

const initialState: CampaignState = {
  campaigns: [],
  campaignTypes: []
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignTypes(state, action) {
      state.campaignTypes = action.payload;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const {
  setCampaignTypes,
  reset,
} = campaignSlice.actions;

export default campaignSlice.reducer;
