//The base data type for the api response
export interface GiftingResponse {
  success: boolean,
  code: number,
  message: string
};

export enum ResponseStatusCode {
  Success = 0,
  Failure = 1,
  NotFound = 2,
  NotAllowed = 3,
  Unauthorized = 10,
  ValidationFailed = 101,
  GiftRecipientNotFound = 201,
  GiftRecipientFailure = 202
}