import { Center, Flex, Text } from "@chakra-ui/react";

export type ClientRecipientDetailCellProps = {
  clientRecipientId?: number;
  email: string;
  firstName: string;
  lastName?: string;
  dob?: Date;
  genderId?: number;
  genderName?: string;
  occupation?: string;
  countryId?: number;
  countryName?: string;
  phoneNumber?: string;
};

const textProps = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  alignSelf: "flex-start",
};

const ClientRecipientDetailCell = (props: ClientRecipientDetailCellProps) => {
  return (
    <>
      <Center cursor={"pointer"}>
        <Flex
          h={"160px"}
          p={"20px 20px"}
          backgroundColor={"#FFFFFF"}
          alignItems={"center"}
          gap={"20px"}
        >
          <Flex direction={"column"} minWidth={"200px"}>
            <Text
              w={"300px"}
              whiteSpace={"nowrap"}
              wordBreak={"break-all"}
              fontSize="xl"
              lineHeight={"40px"}
              as={"b"}
              {...textProps}
            >
              {props.firstName + (props.lastName ? ` ${props.lastName}` : "")}
            </Text>
            <Text>
              {props.email}
            </Text>
            <Text>
              {props.occupation}
            </Text>
            <Text>
              {props.phoneNumber}
            </Text>
          </Flex>
        </Flex>
      </Center>
    </>
  );
};

export default ClientRecipientDetailCell;
