import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import _ from "lodash";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";
import { EditIcon } from "@chakra-ui/icons";
import {
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Table,
} from "@table-library/react-table-library";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { utils, writeFile } from "xlsx";
import CampaignModalContent from "../../features/campaigns/components/modal/CampaignModalContent";
import { useGetCampaignsByCompanyIdQuery, useTriggerCampaignMutation } from "../../features/campaigns/api/CampaignApiSlice";
import { CampaignStatus, CampaignTriggerRequest, CampaignUpdateRequest } from "../../features/campaigns/api/dto/CampaignDto";
import { IoMdPlay } from "react-icons/io";
import { successToast } from "../../utils/ToastUtil";
import { getUserInfoState } from "../../features/authentication/AuthSlice";
import { useAppSelector } from "../../components/common/Hooks";

export default function CampaignListPage() {
  const userInfoState = useAppSelector(getUserInfoState);
  const { data: apiData } = useGetCampaignsByCompanyIdQuery(userInfoState.userInfo.companyId);
  const [openAddCampaign, setOpenAddCampaign] = useBoolean();
  const [companyModalContent, setCampaignModalContent] = useState<JSX.Element>();
  const [triggerCampaign, { isLoading: triggerCampaignLoading }] = useTriggerCampaignMutation();

  const historyBgColor = useColorModeValue("white", "#2D3748");
  const historyFontColor = useColorModeValue("#4a5568", "#f5f5f5");
  const headerBgColor = useColorModeValue("#E2E8F0", "#4A5568");

  const nodes = apiData?.success
    ? apiData?.campaigns.map((campaign) => {
        return {
          id: campaign.campaignId.toString(),
          companyId: campaign.companyId,
          companyName: campaign.companyName,
          name: campaign.name,
          description: campaign.description,
          budget: campaign.budget,
          campaignTypeId: campaign.campaignTypeId,
          campaignTypeName: campaign.campaignTypeName,
          recipientType: campaign.recipientType,
          recipientTypeName: campaign.recipientTypeName,
          startDate: campaign.startDate,
          endDate: campaign.endDate,
          status: campaign.status,
          giftSubCategoryIds: campaign.giftSubCategoryIds,
          recipientIds: campaign.recipientIds,
          isActive: campaign.isActive,
          createdAt: campaign.createdAt,
          createdBy: campaign.createdBy,
          updatedAt: campaign.updatedAt,
          updatedBy: campaign.updatedBy
        };
      })
    : [];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: 80px repeat(3, 200px) 100px repeat(5, 200px) 100px repeat(4, 200px) auto;
      grid-template-rows: 70px repeat(16, 50px);
      grid-auto-rows: repeat(16, 50px);
      border-width: 1px;
      overflow-y: auto;
    `,
    HeaderCell: `
      padding: 12px 20px;
    `,
    Header: `
      .th{
          height: 70px;
          border-bottom: 1px solid black;
          background-color: ${headerBgColor};
      }
    `,
    HeaderRow: `
      height: 70px;
      background-color: ${historyBgColor};
      color: ${historyFontColor};
    `,
    Row: `
      .td{
          height: 50px;
          background-color: ${historyBgColor};
          color: ${historyFontColor};
      }
      .td > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const handleExportXLSX = () => {
    const exportData = data.nodes.map((d) => ({
      id: d.id.toString(),
      company: d.companyName,
      name: d.name,
      description: d.description,
      budget: d.budget,
      campaignType: d.campaignTypeName,
      recipientType: d.recipientTypeName,
      startDate: d.startDate,
      endDate: d.endDate,
      status: CampaignStatus[d.status],
      isActive: d.isActive ? "Yes" : "No",
      createdAt: d.createdAt,
      createdBy: d.createdBy,
      updatedAt: d.updatedAt,
      updatedBy: d.updatedBy,
    }));
    const workSheet = utils.json_to_sheet(exportData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "data");
    writeFile(workBook, "campaigns.xlsx", { compression: true });
  };

  const data = { nodes };
  const pagination = usePagination(data, {
    state: { size: 50 }
  });

  const openAddCampaignModal = () => {
    setOpenAddCampaign.on();
    setCampaignModalContent(
      <CampaignModalContent openModal={setOpenAddCampaign} />
    );
  };

  const openEditCampaignModal = (request: CampaignUpdateRequest, giftSubCategoryIds: number[], recipientIds: number[]) => {
    setOpenAddCampaign.on();
    setCampaignModalContent(
      <CampaignModalContent
        openModal={setOpenAddCampaign}
        editMode={true}
        campaign={request}
        giftSubCategoryIds={giftSubCategoryIds}
        recipientIds={recipientIds}
      />
    );
  };

  const submitTriggerCampaign = async (companyId: number, campaignId: number) => {
    let request: CampaignTriggerRequest = {
      companyId: companyId,
      campaignId: campaignId
    };
    
    let result = await triggerCampaign(request);

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast("Trigger Campaign Success!");
  };

  return (
    <>
      <Modal isOpen={openAddCampaign} onClose={setOpenAddCampaign.off} closeOnOverlayClick={false}>
        {companyModalContent}
      </Modal>
      <Flex w={"100%"} gap={"10px"} flexDirection={"column"}>
        <Box
          px={"1%"}
          py={"15px"}
          h={"70px"}
        >
          <HStack justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={() => openAddCampaignModal()}>
              Create
            </Button>
            <ConfirmPopover
              placement={"left-end"}
              confirmText={"Are you sure to download table campaigns.xlsx?"}
              onConfirm={() => handleExportXLSX()}
              triggerButton={<Button colorScheme={"green"}>Download</Button>}
            />
          </HStack>
        </Box>
        <Flex px={"1%"} flexDirection={"column"} h={"calc(100vh - 150px)"}>
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, fixedHeader: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Company</HeaderCell>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Description</HeaderCell>
                    <HeaderCell>Budget</HeaderCell>
                    <HeaderCell>Campaign Type</HeaderCell>
                    <HeaderCell>Recipient Type</HeaderCell>
                    <HeaderCell>Start Date</HeaderCell>
                    <HeaderCell>End Date</HeaderCell>
                    <HeaderCell>Status</HeaderCell>
                    <HeaderCell>Is Active</HeaderCell>
                    <HeaderCell>Created At</HeaderCell>
                    <HeaderCell>Created By</HeaderCell>
                    <HeaderCell>Updated At</HeaderCell>
                    <HeaderCell>Updated By</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={_.toString(item.id)}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.companyName}</Cell>
                      <Cell>{item.name}</Cell>
                      <Cell>{item.description}</Cell>
                      <Cell>{item.budget}</Cell>
                      <Cell>{item.campaignTypeName}</Cell>
                      <Cell>{item.recipientTypeName}</Cell>
                      <Cell>{item.startDate != null ? moment(item.startDate).format(DateFormat) : '-'}</Cell>
                      <Cell>{item.endDate != null ? moment(item.endDate).format(DateFormat) : '-'}</Cell>
                      <Cell>{CampaignStatus[item.status]}</Cell>
                      <Cell>{item.isActive ? "Yes" : "No"}</Cell>
                      <Cell>{moment(item.createdAt).format(DateFormat)}</Cell>
                      <Cell>{item.createdBy}</Cell>
                      <Cell>{moment(item.updatedAt).format(DateFormat)}</Cell>
                      <Cell>{item.updatedBy}</Cell>
                      <Cell>
                        <Flex gap={"10px"}>
                          <EditIcon
                            cursor="pointer"
                            color={"blue.400"}
                            onClick={() => {
                              let request: CampaignUpdateRequest = {
                                campaignId: parseInt(item.id),
                                companyId: item.companyId,
                                name: item.name,
                                description: item.description,
                                budget: item.budget,
                                campaignTypeId: item.campaignTypeId,
                                recipientType: item.recipientType,
                                startDate: item.startDate,
                                giftSubCategories: [],
                                recipients: []
                              };
                              let giftSubCategoryIds: number[] = item.giftSubCategoryIds;
                              let recipientIds: number[] = item.recipientIds;

                              openEditCampaignModal(request, giftSubCategoryIds, recipientIds);
                            }}

                          />
                          {_.toNumber(item.status) === CampaignStatus.Pending ? <Icon as={IoMdPlay}
                            cursor="pointer"
                            color={"blue.400"} 
                            onClick={() => {submitTriggerCampaign(parseInt(item.companyId), parseInt(item.id))}}
                          /> : ""}
                        </Flex>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </Flex>
      </Flex>
    </>
  );
}
