import { ReactNode } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import { IconType } from "react-icons";
import Logo from "../../assets/img/company-logo.svg";
import { getActiveNavItems } from "../../components/common/SidebarUtils";
import { useAppSelector } from "../../components/common/Hooks";
import { LinkItemProps } from "../../types/LinkItemProps";
import SidebarProfileItem from "./profile/SidebarProfileItem";

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH={"100vh"} bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const displayNavItem = (onClose: () => void, link: LinkItemProps) => {
  if (link.subItems) {
    return (
      <Accordion key={link.name} allowToggle>
        <AccordionItem style={{ borderWidth: "0px" }}>
          <AccordionButton pl="8" h={"48px"}>
            <Flex alignItems={"center"} minWidth={"100"}>
              {link.icon && (
                <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: "white",
                  }}
                  as={link.icon}
                />
              )}
              {link.name}
            </Flex>
            <AccordionIcon ml={"5"} />
          </AccordionButton>
          <AccordionPanel style={{ paddingBottom: 0 }}>
            <Box>
              {link.subItems.map((l) => (
                <NavItem
                  key={l.name}
                  to={
                    link.extendsParentPath
                      ? link.pathname + "/" + l.pathname
                      : l.pathname
                  }
                  icon={l.icon}
                  onClick={onClose}
                >
                  {l.name}
                </NavItem>
              ))}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  } else {
    return (
      <NavItem
        key={link.name}
        to={link.pathname}
        icon={link.icon}
        onClick={onClose}
      >
        {link.name}
      </NavItem>
    );
  }
};

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const linkItems = getActiveNavItems(userInfo?.roles);

  return (
    <Box
      zIndex={1000}
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box
          left="5"
          top="5"
          right="0"
          bottom="0"
          position="absolute"
          width="180px"
          height="50px"
          bgSize="contain"
          bgImage={Logo}
          bgRepeat={"no-repeat"}
        ></Box>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Flex
        flexDirection={"column"}
        h={"calc(100vh - 150px)"}
        color="black"
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "8px",
          },
        }}
      >
        {linkItems?.map((link) => displayNavItem(onClose, link))}
      </Flex>
      <SidebarProfileItem />
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  to: string;
  icon: IconType;
  children: string;
}

const NavItem = ({ to, icon, children, ...rest }: NavItemProps) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
};
