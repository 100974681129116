import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { envConfig } from "../utils/ConfigUtil";

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["giftCategories", "companies", "users", "campaigns", "clients", "clientRecipients", "gifts", "companiesWithClients", "giftRecipients"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig().url}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({}),
});
