import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import _ from "lodash";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";
import { EditIcon } from "@chakra-ui/icons";
import {
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Table,
} from "@table-library/react-table-library";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { utils, writeFile } from "xlsx";
import { useGetGiftsQuery } from "../../features/gifts/api/GiftApiSlice";
import GiftModalContent from "../../features/gifts/components/modal/GiftModalContent";

export default function GiftListPage() {
  const { data: apiData } = useGetGiftsQuery();
  const [openAddGift, setOpenAddGift] = useBoolean();
  const [giftModalContent, setGiftModalContent] = useState<JSX.Element>();

  const historyBgColor = useColorModeValue("white", "#2D3748");
  const historyFontColor = useColorModeValue("#4a5568", "#f5f5f5");
  const headerBgColor = useColorModeValue("#E2E8F0", "#4A5568");

  const nodes = apiData?.success
    ? apiData?.gifts.map((gift) => {
        return {
          id: gift.giftId.toString(),
          name: gift.name,
          price: gift.price,
          giftSubCategoryId: gift.giftSubCategoryId,
          giftSubCategoryName: gift.giftSubCategoryName,
          description: gift.description,
          otherDetails: gift.otherDetails,
          location: gift.location,
          deliveryOption: gift.deliveryOption,
          imagePath: gift.imagePath,
          isActive: gift.isActive,
          createdAt: gift.createdAt,
          createdBy: gift.createdBy,
          updatedAt: gift.updatedAt,
          updatedBy: gift.updatedBy
        };
      })
    : [];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: 80px 200px 100px 200px 200px 200px 200px 200px 200px auto;
      grid-template-rows: 70px repeat(10, 50px);
      grid-auto-rows: repeat(10, 50px);
      border-width: 1px;
      overflow-y: auto;
    `,
    HeaderCell: `
      padding: 12px 20px;
    `,
    Header: `
      .th{
          height: 70px;
          border-bottom: 1px solid black;
          background-color: ${headerBgColor};
      }
    `,
    HeaderRow: `
      height: 70px;
      background-color: ${historyBgColor};
      color: ${historyFontColor};
    `,
    Row: `
      .td{
          height: 50px;
          background-color: ${historyBgColor};
          color: ${historyFontColor};
      }
      .td > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const handleExportXLSX = () => {
    const exportData = data.nodes.map((d) => ({
      id: d.id.toString(),
      name: d.name,
      price: d.price,
      giftSubCategory: d.giftSubCategoryName,
      isActive: d.isActive ? "Yes" : "No",
      createdAt: d.createdAt,
      createdBy: d.createdBy,
      updatedAt: d.updatedAt,
      updatedBy: d.updatedBy,
    }));
    const workSheet = utils.json_to_sheet(exportData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "data");
    writeFile(workBook, "gifts.xlsx", { compression: true });
  };

  const data = { nodes };
  const pagination = usePagination(data, {
    state: { size: 50 }
  });

  const openAddGiftModal = () => {
    setOpenAddGift.on();
    setGiftModalContent(
      <GiftModalContent openModal={setOpenAddGift} />
    );
  };

  const openEditGiftModal = (
    giftId: number,
    name: string,
    price: number,
    giftSubCategoryId: number,
    description?: string,
    otherDetails?: string,
    location?: string,
    deliveryOption?: string,
    imagePath?: string
  ) => {
    setOpenAddGift.on();
    setGiftModalContent(
      <GiftModalContent
        openModal={setOpenAddGift}
        editMode={true}
        giftId={giftId}
        name={name}
        price={price}
        giftSubCategoryId={giftSubCategoryId}
        description={description}
        otherDetails={otherDetails}
        location={location}
        deliveryOption={deliveryOption}
        imagePath={imagePath}
      />
    );
  };

  return (
    <>
      <Modal size={"xl"} isOpen={openAddGift} onClose={setOpenAddGift.off}>
        {giftModalContent}
      </Modal>
      <Flex w={"100%"} gap={"10px"} flexDirection={"column"}>
        <Box
          px={"1%"}
          py={"15px"}
          h={"70px"}
        >
          <HStack justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={() => openAddGiftModal()}>
              Create
            </Button>
            <ConfirmPopover
              placement={"left-end"}
              confirmText={"Are you sure to download table gifts.xlsx?"}
              onConfirm={() => handleExportXLSX()}
              triggerButton={<Button colorScheme={"green"}>Download</Button>}
            />
          </HStack>
        </Box>
        <Flex px={"1%"} flexDirection={"column"} h={"calc(100vh - 150px)"}>
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, fixedHeader: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Price</HeaderCell>
                    <HeaderCell>Gift Sub Category</HeaderCell>
                    <HeaderCell>Is Active</HeaderCell>
                    <HeaderCell>Created At</HeaderCell>
                    <HeaderCell>Created By</HeaderCell>
                    <HeaderCell>Updated At</HeaderCell>
                    <HeaderCell>Updated By</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={_.toString(item.id)}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.name}</Cell>
                      <Cell>{item.price}</Cell>
                      <Cell>{item.giftSubCategoryName}</Cell>
                      <Cell>{item.isActive ? "Yes" : "No"}</Cell>
                      <Cell>{moment(item.createdAt).format(DateFormat)}</Cell>
                      <Cell>{item.createdBy}</Cell>
                      <Cell>{moment(item.updatedAt).format(DateFormat)}</Cell>
                      <Cell>{item.updatedBy}</Cell>
                      <Cell>
                        <Flex gap={"10px"}>
                          <EditIcon
                            onClick={() =>
                              openEditGiftModal(
                                parseInt(item.id),
                                item.name,
                                item.price,
                                item.giftSubCategoryId,
                                item.description,
                                item.otherDetails,
                                item.location,
                                item.deliveryOption,
                                item.imagePath
                              )
                            }
                            cursor="pointer"
                            color={"blue.400"}
                          />
                        </Flex>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </Flex>
      </Flex>
    </>
  );
}
