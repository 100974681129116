import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ClientsDto } from "./api/dto/ClientDto";

type ClientState = {
  clients: ClientsDto[]
}

const initialState: ClientState = {
  clients: []
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
});

export const {
  reset,
} = clientSlice.actions;

export default clientSlice.reducer;
