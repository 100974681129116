import { Box, Center, Text, useToast, VStack } from "@chakra-ui/react";
import _ from "lodash";
import ClientSubmitGiftSelectionPage from "./ClientSubmitGiftSelection";
import { useAppDispatch } from "../../components/common/Hooks";
import {
  StepProgress,
  StepStates,
} from "../../components/common/step-progress/StepProgress";
import { useUpdateGiftSelectionMutation } from "../../features/gift-recipients/api/GiftRecipientApiSlice";
import { store } from "../../components/common/Store";
import { GiftRecipientGiftSelectionUpdateRequest } from "../../features/gift-recipients/api/dto/GiftRecipientDto";
import { useState } from "react";
import BackgroundThankYou from "../../assets/img/bg-thank-you.png";
import ClientGiftSelectionPage from "./ClientGiftSelection";
import ClientConfirmGiftSelectionPage from "./ClientConfirmGiftSelection";
import { Colors } from "../../types/Common";

function ClientPickGiftPage() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [updateGiftSelection, { isLoading: updateGiftSelectionLoading }] =
    useUpdateGiftSelectionMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  function displayWarningToast(title: string, description: string) {
    toast({
      title: title || "Warning",
      status: "warning",
      description,
      duration: 2000,
      position: "bottom",
    });
  }

  function validateGiftSelection(): boolean {
    const state = store.getState();
    if (!state.giftRecipient.selectedGiftSubCategoryId) {
      displayWarningToast("Category", "Please choose a category");
      return false;
    }

    if (!state.giftRecipient.selectedGiftId) {
      displayWarningToast("Gift", "Please choose a gift");
      return false;
    }

    return true;
  }

  function validateConfirmedGiftSelection(): boolean {
    const state = store.getState();
    if (!state.giftRecipient.confirmedSelectedGiftId) {
      displayWarningToast("Gift", "Please confirm gift selection");
      return false;
    }

    return true;
  }

  function validateRecipientDetails(): boolean {
    const state = store.getState();
    let clientRecipient = state.giftRecipient.giftRecipient?.clientRecipient;
    if (clientRecipient !== undefined) {
      if (clientRecipient.pickUpOption == undefined || clientRecipient.pickUpOption <= 0) {
        displayWarningToast("Warning", "Pick Up Option is required");
        return false;
      }

      if (_.isEmpty(clientRecipient.firstName)) {
        displayWarningToast("Warning", "First Name is required");
        return false;
      }

      if (_.isEmpty(clientRecipient.phoneNumber)) {
        displayWarningToast("Warning", "Phone Number is required");
        return false;
      }

      if (_.isEmpty(clientRecipient.addresses[0].addressLineOne)) {
        displayWarningToast("Warning", "Address Line One is required");
        return false;
      }

      if (_.isEmpty(clientRecipient.addresses[0].postalCode)) {
        displayWarningToast("Warning", "Postal Code is required");
        return false;
      }
    }

    return true;
  }

  const submitSelectedGift = async () => {
    try {
      const state = store.getState();
      let clientRecipient = state.giftRecipient.giftRecipient?.clientRecipient!;
      let request: GiftRecipientGiftSelectionUpdateRequest = {
        transactionId: state.giftRecipient.transactionId,
        giftId: state.giftRecipient.selectedGiftId ?? 0,
        firstName: clientRecipient.firstName,
        lastName: clientRecipient.lastName,
        phoneNumber: clientRecipient.phoneNumber ?? "",
        addressLineOne: clientRecipient?.addresses[0].addressLineOne,
        addressLineTwo: clientRecipient?.addresses[0].addressLineTwo,
        postalCode: clientRecipient?.addresses[0].postalCode,
        giftPickUpOption: clientRecipient.pickUpOption
      };
      let result = await updateGiftSelection(request);

      if ("error" in result || !result.data.success) {
        return;
      }

      setIsSubmitted(true);
    } catch (err) {
      console.error("Failed to select gift: ", err);
    }
  };

  return (
    <>
      <Box hidden={isSubmitted}>
        <StepProgress
          submitLoading={updateGiftSelectionLoading}
          onSubmit={submitSelectedGift}
          steps={[
            {
              title: "",
              subtitle: "Choose Gift",
              name: "step 1",
              content: <ClientGiftSelectionPage />,
              state: StepStates.NOT_STARTED,
              validator: validateGiftSelection,
            },
            {
              title: "",
              subtitle: "Confirm Gift",
              name: "step 2",
              content: <ClientConfirmGiftSelectionPage />,
              state: StepStates.NOT_STARTED,
              validator: validateConfirmedGiftSelection,
            },
            {
              title: "",
              subtitle: "Checkout",
              name: "step 3",
              content: <ClientSubmitGiftSelectionPage />,
              state: StepStates.NOT_STARTED,
              validator: validateRecipientDetails,
            },
          ]}
        />
      </Box>
      <Box
        w="100%"
        h={"calc(100vh - 60px)"}
        bgImage={BackgroundThankYou}
        bgSize={"contain"}
        bgRepeat={"no-repeat"}
        hidden={!isSubmitted}
      >
        <VStack align={"center"} mt={10}>
          <Text
            align={"center"}
            fontSize={56}
            fontWeight={"semibold"}
            color={Colors.SmilieText}
          >
            Order placed, thanks!
          </Text>
          <Text align={"center"} fontSize={32} fontWeight={"semibold"}>
            We will keep you posted on the gift delivery status.
          </Text>
        </VStack>
      </Box>
    </>
  );
}

export default ClientPickGiftPage;
