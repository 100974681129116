import { GiftingResponse } from "../../../../types/GiftingResponse";
import { ReactSelectOptionType } from "../../../../types/ReactSelectOptionType";

export interface CampaignsDto extends GiftingResponse {
  campaigns: CampaignDto[];
}

export type CampaignDto = {
  campaignId: number;
  companyId: number;
  companyName: string;
  name: string;
  description?: string;
  budget: number;
  campaignTypeId: number;
  campaignTypeName: string;
  recipientType?: number;
  recipientTypeName: string;
  startDate?: Date;
  endDate?: Date;
  status: number;
  giftSubCategoryIds: number[];
  recipientIds: number[];
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface CampaignCreateRequest {
  companyId: number;
  name: string;
  description?: string;
  budget: number | undefined;
  campaignTypeId: number;
  recipientType?: number;
  startDate?: Date;
  endDate?: Date;
  defaultMessageToGiftRecipient?: string;
  giftSubCategories: ReactSelectOptionType[];
  recipients: ReactSelectOptionType[];
}

export interface CampaignCreateResponse extends GiftingResponse {
  campaignId: number;
}

export interface CampaignUpdateRequest extends CampaignCreateRequest {
  campaignId: number | undefined;
}

export interface CampaignTriggerRequest {
  companyId: number;
  campaignId: number;
}

export interface CampaignDeleteRequest {
  companyId: number;
  campaignId: number;
}

export enum CampaignStatus {
  Pending = 1,
  Ongoing = 2,
  Completed = 3,
  Draft = 4
}

export enum CampaignRecipientType {
  Internal = 1,
  External = 2
}