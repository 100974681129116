import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../components/common/Hooks";
import {
  getGiftRecipientState,
  setConfirmedSelectedGiftId,
} from "../../features/gift-recipients/GiftRecipientSlice";
import { setAddNextClickCount } from "../../features/step-progress/StepProgressSlice";
import { Colors } from "../../types/Common";
import { warningToast } from "../../utils/ToastUtil";

function ClientConfirmGiftSelectionPage() {
  const dispatch = useAppDispatch();
  const giftRecipientState = useAppSelector(getGiftRecipientState);
  const [optionValue, setOptionValue] = useState("");

  let selectedGiftSubCategory =
    giftRecipientState.giftRecipient!.giftSubCategories.filter(
      (item) =>
        item.giftSubCategoryId === giftRecipientState.selectedGiftSubCategoryId
    );

  let selectedGift = selectedGiftSubCategory![0].gifts.filter(
    (item) => item.giftId == giftRecipientState.selectedGiftId
  )[0];

  const handleSetConfirmedGiftId = () => {
    if (!optionValue) {
      warningToast("Please select an option");
      return;
    }

    dispatch(setConfirmedSelectedGiftId(selectedGift.giftId));
    dispatch(setAddNextClickCount());
  };

  return (
    <Center>
      <Grid
        templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        minW={{ sm: "450px", md: "800px", lg: "1024px" }}
        gap={5}
      >
        <GridItem>
          <Center>
            <Image
              w={{ sm: "100%", md: "90%" }}
              h={"50%"}
              src={selectedGift?.imagePath}
              alt="Image not found"
              objectFit={"cover"}
            ></Image>
          </Center>
        </GridItem>
        <GridItem>
          <Box>
            <Flex w="100%" flexDirection={"column"}>
              <Text fontSize={32} fontWeight={"semibold"} mb={"5"}>
                {selectedGift?.name}
              </Text>
              <Box
                p={"24px"}
                border={"1px solid #FFBC9E"}
                borderRadius={"lg"}
                mb={"5"}
              >
                <VStack align={"left"} gap={2}>
                  <Text fontWeight={"bold"} color={"#EE562A"}>
                    Select Option
                  </Text>
                  <RadioGroup onChange={setOptionValue}>
                    <Stack direction="column">
                      <Radio value={selectedGift?.giftId.toString()} colorScheme={"orange"}>{selectedGift?.name}</Radio>
                    </Stack>
                  </RadioGroup>
                  <Button
                    type="button"
                    w={"max-content"}
                    textTransform={"uppercase"}
                    bgColor={Colors.SmilieText}
                    color="white"
                    px={5}
                    onClick={handleSetConfirmedGiftId}
                  >
                    Choose Gift
                  </Button>
                </VStack>
              </Box>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                  <AccordionButton py={4}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"bold"}
                    >
                      Description
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text fontSize={16} mb={"5"} textAlign={"justify"} whiteSpace={"pre-line"}>
                      {selectedGift?.description}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton py={4}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"bold"}
                    >
                      Other Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text fontSize={16} mb={"5"} textAlign={"justify"} whiteSpace={"pre-line"}>
                      {selectedGift?.otherDetails}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton py={4}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"bold"}
                    >
                      Location
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text fontSize={16} mb={"5"} textAlign={"justify"} whiteSpace={"pre-line"}>
                      {selectedGift?.location}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton py={4}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"bold"}
                    >
                      Delivery Options
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text fontSize={16} mb={"5"} textAlign={"justify"} whiteSpace={"pre-line"}>
                      {selectedGift?.deliveryOption}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Center>
  );
}

export default ClientConfirmGiftSelectionPage;
