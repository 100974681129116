interface GiftingConfig {
  url: string,
}

const DEFAULT_CONFIG: GiftingConfig = {
  url: `${process.env.REACT_APP_API_URL}`,
}

export const envConfig = (): GiftingConfig => {
  // @ts-ignore
  return typeof externalConfig !== 'undefined' ? {...DEFAULT_CONFIG, ...externalConfig}
      : DEFAULT_CONFIG;
};