import {
  Divider,
  Flex,
  IconButton,
  Modal,
  Text,
  useBoolean
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ClientRecipientEditContent from "./ClientRecipientEditContent";
import ConfirmPopover from "../../../components/common/popover/ConfirmPopover";
import { useState } from "react";
import { ClientRecipientCreateRequest } from "../api/dto/ClientDto";
import { useDeleteClientRecipientMutation } from "../api/ClientApiSlice";
import { successToast } from "../../../utils/ToastUtil";

const itemProps = {
  alignItems: "center",
};

const labelProps = {
  width: "160px"
};

const textProps = {
  fontWeight: "bold",
  marginLeft: "10px",
  width: "200px",
};

interface ClientRecipientInfoPopContentProps extends ClientRecipientCreateRequest {
  clientRecipientId: number;
}

const ClientRecipientInfoPopContent = (props: ClientRecipientInfoPopContentProps) => {
  const [deleteClientRecipient, { isLoading: deleteLoading }] =
    useDeleteClientRecipientMutation();
  const { companyId, clientId, clientRecipientId, firstName, lastName, email, occupation, phoneNumber, genderId, countryId, addressLineOne, addressLineTwo, postalCode } = props;
  const [editOpen, setEditOpen] = useBoolean();
  const [deletePopVis, setDeletePopVis] = useState(false);

  const handleDelete = async () => {
    const result = await deleteClientRecipient({ companyId, clientId, clientRecipientId });
    if ("error" in result) {
      return;
    }

    successToast("Client Recipient Delete Success!");
  };

  return (
    <>
      <Modal isOpen={editOpen} onClose={setEditOpen.off}>
        <ClientRecipientEditContent
          setEditOpen={setEditOpen}
          companyId={companyId}
          clientId={clientId}
          clientRecipientId={clientRecipientId}
          email={email}
          firstName={firstName}
          lastName={lastName}
          occupation={occupation}
          phoneNumber={phoneNumber}
          genderId={genderId}
          countryId={countryId}
          addressLineOne={addressLineOne}
          addressLineTwo={addressLineTwo}
          postalCode={postalCode}
        />
      </Modal>
      <Flex direction={"column"} alignItems={"center"} p={"20px 0"}>
        <Flex mt={"20px"} gap={"25px"}>
          <IconButton
            rounded={"full"}
            aria-label={"edit"}
            icon={<EditIcon />}
            color={"rgb(62,169,221)"}
            bg={"rgb(233,243,252)"}
            _hover={{
              backgroundColor: "rgb(233,243,252)",
            }}
            onClick={setEditOpen.on}
          />
          <ConfirmPopover
            isOpen={deletePopVis}
            onClose={() => setDeletePopVis(false)}
            onOpen={() => setDeletePopVis(true)}
            confirmText={`Are you sure to delete ${email}`}
            onConfirm={handleDelete}
            triggerButton={
              <IconButton
                isLoading={deleteLoading}
                rounded={"full"}
                _hover={{ backgroundColor: "rgb(255,201,192)" }}
                aria-label={"update"}
                icon={<DeleteIcon />}
                color={"rgb(252,54,52)"}
                bg={"rgb(255,201,192)"}
              />
            }
          ></ConfirmPopover>
        </Flex>
        <Divider mt={"20px"} />
        <Flex
          direction={"column"}
          alignSelf={"flex-start"}
          mt={"10px"}
          gap={"10px"}
        >
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              First Name
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {firstName}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Last Name
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {lastName}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Email
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {email}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Occupation
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {occupation}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Phone Number
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {phoneNumber}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Address Line 1
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {addressLineOne}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Address Line 2
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {addressLineTwo}
            </Text>
          </Flex>
          <Flex {...itemProps}>
            <Text textAlign={"center"} {...labelProps}>
              Postal Code
            </Text>
            <Text wordBreak={"break-word"} {...textProps}>
              {postalCode}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ClientRecipientInfoPopContent;
