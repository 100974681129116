import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../components/common/Hooks";
import {
  getGiftRecipientState,
  setClientRecipientAddressLineOne,
  setClientRecipientAddressLineTwo,
  setClientRecipientFirstName,
  setClientRecipientLastName,
  setClientRecipientPhoneNumber,
  setClientRecipientPickUpOption,
  setClientRecipientPostalCode,
} from "../../features/gift-recipients/GiftRecipientSlice";
import { GiftPickUpOption } from "../../features/gifts/api/dto/GiftDto";
import { setAddBackClickCount } from "../../features/step-progress/StepProgressSlice";
import { Colors } from "../../types/Common";

function ClientSubmitGiftSelectionPage() {
  const dispatch = useAppDispatch();
  const giftRecipientState = useAppSelector(getGiftRecipientState);
  const recipient = giftRecipientState.giftRecipient?.clientRecipient;
  const selectedGiftSubCategory =
    giftRecipientState.giftRecipient!.giftSubCategories.filter(
      (item) =>
        item.giftSubCategoryId === giftRecipientState.selectedGiftSubCategoryId
    );
  const selectedGift = selectedGiftSubCategory[0].gifts.filter(
    (item) => item.giftId === giftRecipientState.selectedGiftId
  );
  const [pickUpOptionValue, setPickUpOptionValue] = useState("");

  const handleChangeGift = () => {
    dispatch(setAddBackClickCount());
  };

  useEffect(() => {
    if (pickUpOptionValue) {
      dispatch(setClientRecipientPickUpOption(parseInt(pickUpOptionValue)));
    }
  }, [pickUpOptionValue]);

  return (
    <Center>
      <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)" , md: "repeat(5, 1fr)" }} gap={5}>
        <GridItem colSpan={{ base: 1, sm: 1, md: 5 }}>
          <Center fontSize={24} fontWeight={"semibold"}>
            Checkout
          </Center>
        </GridItem>
        <GridItem
          rowSpan={{ base: 1, sm: 1, md: 2}}
          colSpan={{ base: 1, sm: 1, md: 3}}
          bg="white"
          rounded="lg"
          boxShadow={"lg"}
        >
          <VStack align="normal" m="20px">
            <Box>
              <Text fontSize={24} fontWeight={"medium"} mb={5}>
                Select one option
              </Text>
              <RadioGroup onChange={setPickUpOptionValue} mb={5}>
                <Stack direction="column">
                  <Radio
                    value={GiftPickUpOption.SelfPickUp.toString()}
                    colorScheme={"orange"}
                  >
                    Self Pick Up
                  </Radio>
                  <Radio
                    value={GiftPickUpOption.Delivery.toString()}
                    colorScheme={"orange"}
                  >
                    Delivery
                  </Radio>
                </Stack>
              </RadioGroup>
              <Divider></Divider>
              <Text fontSize={24} fontWeight={"medium"} mt={5} mb={5}>
                Where should we ship your order?
              </Text>
              <FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Email Address
                  </FormLabel>
                  <Input
                    value={recipient?.email}
                    type="text"
                    placeholder="Email address"
                    size="lg"
                    disabled={true}
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    First Name
                  </FormLabel>
                  <Input
                    value={recipient?.firstName}
                    onChange={(e) => {
                      dispatch(setClientRecipientFirstName(e.target.value));
                    }}
                    type="text"
                    placeholder="First Name"
                    size="lg"
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Last Name
                  </FormLabel>
                  <Input
                    value={recipient?.lastName}
                    onChange={(e) => {
                      dispatch(setClientRecipientLastName(e.target.value));
                    }}
                    type="text"
                    placeholder="Last Name"
                    size="lg"
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Address Line One
                  </FormLabel>
                  <Input
                    value={recipient?.addresses[0].addressLineOne}
                    onChange={(e) => {
                      dispatch(
                        setClientRecipientAddressLineOne(e.target.value)
                      );
                    }}
                    type="text"
                    placeholder="Address Line One"
                    size="lg"
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Address Line Two
                  </FormLabel>
                  <Input
                    value={recipient?.addresses[0].addressLineTwo}
                    onChange={(e) => {
                      dispatch(
                        setClientRecipientAddressLineTwo(e.target.value)
                      );
                    }}
                    type="text"
                    placeholder="Address Line Two"
                    size="lg"
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Postal Code
                  </FormLabel>
                  <Input
                    value={recipient?.addresses[0].postalCode}
                    onChange={(e) => {
                      dispatch(setClientRecipientPostalCode(e.target.value));
                    }}
                    type="text"
                    placeholder="Postal Code"
                    size="lg"
                  />
                </FormControl>
                <FormControl ms="4px" mb="12px">
                  <FormLabel fontSize="sm" fontWeight="normal">
                    Phone Number
                  </FormLabel>
                  <Input
                    value={recipient?.phoneNumber}
                    onChange={(e) => {
                      dispatch(setClientRecipientPhoneNumber(e.target.value));
                    }}
                    type="text"
                    placeholder="Phone Number"
                    size="lg"
                  />
                </FormControl>
              </FormControl>
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={{base: 1, sm: 1, md: 2}} bg="white" rounded="lg" boxShadow={"lg"}>
          <VStack align="normal" m="20px" gap={2}>
            <Text fontSize={24} fontWeight={"medium"} mb={3}>
              Order Summary
            </Text>
            <Flex direction={"row"} gap={5} mb={5}>
              <Image
                src={selectedGift[0].imagePath}
                alt="Image not found"
                w={"40%"}
                h={"150px"}
                objectFit={"cover"}
                borderRadius="10px"
              ></Image>
              <VStack>
                <Text>{selectedGift[0].name}</Text>
                <Text
                  textTransform={"uppercase"}
                  textDecoration={"underline"}
                  color={Colors.SmilieText}
                  onClick={handleChangeGift}
                  cursor={"pointer"}
                >
                  Change Gift
                </Text>
              </VStack>
            </Flex>
            <Flex direction={"row"} my={5}>
              <Text>Shipping</Text>
              <Spacer></Spacer>
              <Text align={"right"}>S$ 0.00</Text>
            </Flex>
            <Divider></Divider>
            <Flex direction={"row"}>
              <Text fontWeight={"bold"}>Total</Text>
              <Spacer></Spacer>
              <Text
                fontWeight={"bold"}
                align={"right"}
                color={Colors.SmilieText}
              >
                S$ 0.00
              </Text>
            </Flex>
          </VStack>
        </GridItem>
      </Grid>
    </Center>
  );
}

export default ClientSubmitGiftSelectionPage;
