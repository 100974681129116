import { ChangeEvent, useReducer, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch
} from "@chakra-ui/react";
import _ from "lodash";
import { useCreateGiftSubCategoryMutation } from "../api/GiftApiSlice";
import { GiftSubCategoryInfoAction } from "../../../types/gifts/GiftCategory";
import { FileUploadModel } from "../../../types/FileUploadModel";
import { convertToBase64 } from "../../../utils/FileUploadUtil";
import { GiftSubCategoryCreateDto } from "../api/dto/GiftCategoryDto";
import { successToast } from "../../../utils/ToastUtil";

type GiftSubCategoryAddContentProps = {
  setAddOpen: { on: () => void; off: () => void; toggle: () => void };
  giftCategoryId?: number;
  isForOnboarding: boolean;
};

const GiftSubCategoryAddContent = (props: GiftSubCategoryAddContentProps) => {
  const { giftCategoryId, isForOnboarding } = props;
  const [submit, { isLoading: submitLoading, isError: addError }] =
    useCreateGiftSubCategoryMutation();
  const [isError, setIsError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const initialValue: GiftSubCategoryCreateDto = {
    giftSubCategoryId: 0,
    name: "",
    giftCategoryId: giftCategoryId || 0,
    isForOnboarding: true,
    file: null,
  };

  const giftSubCategoryReducer = (
    state: GiftSubCategoryCreateDto,
    action: GiftSubCategoryInfoAction
  ) => {
    const { payload } = action;

    return { ...state, [action.type]: payload };
  };

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e != null && e.target.files != null && e.target.files?.length > 0) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (isError || state.name.trim() === "") {
      setIsError(true);
      return;
    }

    let fileUploadModel: FileUploadModel = { fileName: "", base64String: "" };
    if (uploadedFile != null) {
      fileUploadModel.fileName = uploadedFile.name;
      fileUploadModel.base64String = (await convertToBase64(
        uploadedFile
      )) as string;
      if (fileUploadModel.base64String) {
        fileUploadModel.base64String =
          fileUploadModel.base64String.split(";base64,")[1];
      }

      state.file = fileUploadModel;
    }

    const result = await submit(state);
    if ("error" in result) {
      return;
    }

    if (!addError) {
      successToast("Success");
    }
    props.setAddOpen.off();
  };

  const [state, dispatch] = useReducer(giftSubCategoryReducer, initialValue);

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Sub Category</ModalHeader>
        <Divider />
        <ModalBody pb={"30px"}>
          <FormControl
            isRequired
            isInvalid={isError && state.name.trim() === ""}
          >
            <FormLabel>Name</FormLabel>
            <Input
              value={state.name}
              placeholder={"Name"}
              onChange={(e) => {
                if (state.name.trim() !== "") {
                  setIsError(false);
                }
                dispatch({ type: "name", payload: e.target.value });
              }}
            ></Input>
            {isError && state.name.trim() === "" && (
              <FormErrorMessage>Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Is Show During Onboarding</FormLabel>
            <Switch
              id="isForOnboarding"
              isChecked={state.isForOnboarding}
              onChange={(e) => {
                dispatch({
                  type: "isForOnboarding",
                  payload: e.target.checked,
                });
              }}
            ></Switch>
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Upload Image</FormLabel>
            <Input type="file" onChange={saveFile} />
          </FormControl>
        </ModalBody>
        <ModalFooter gap={"20px"}>
          <Button
            colorScheme="blue"
            onClick={_.debounce(handleSubmit, 200)}
            isLoading={submitLoading}
          >
            Save
          </Button>
          <Button onClick={props.setAddOpen.off}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default GiftSubCategoryAddContent;
