import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import _ from "lodash";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";
import { useGetCompaniesQuery } from "../../features/companies/api/CompanyApiSlice";
import { EditIcon } from "@chakra-ui/icons";
import {
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Table,
} from "@table-library/react-table-library";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { utils, writeFile } from "xlsx";
import CompanyModalContent from "../../features/companies/components/modal/CompanyModalContent";

export default function CompanyListPage() {
  const { data: apiData } = useGetCompaniesQuery();
  const [openAddCompany, setOpenAddCompany] = useBoolean();
  const [companyModalContent, setCompanyModalContent] = useState<JSX.Element>();

  const historyBgColor = useColorModeValue("white", "#2D3748");
  const historyFontColor = useColorModeValue("#4a5568", "#f5f5f5");
  const headerBgColor = useColorModeValue("#E2E8F0", "#4A5568");

  const nodes = apiData?.success
    ? apiData?.companies.map((company) => {
        return {
          id: company.companyId.toString(),
          name: company.name,
          logoImagePath: company.logoImagePath,
          isActive: company.isActive,
          createdAt: company.createdAt,
          createdBy: company.createdBy,
          updatedAt: company.updatedAt,
          updatedBy: company.updatedBy
        };
      })
    : [];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: 90px 200px 100px 200px 200px 200px 200px auto;
      grid-template-rows: 70px repeat(8, 50px);
      grid-auto-rows: repeat(8, 50px);
      border-width: 1px;
      overflow-y: auto;
    `,
    HeaderCell: `
      padding: 12px 20px;
    `,
    Header: `
      .th{
          height: 70px;
          border-bottom: 1px solid black;
          background-color: ${headerBgColor};
      }
    `,
    HeaderRow: `
      height: 70px;
      background-color: ${historyBgColor};
      color: ${historyFontColor};
    `,
    Row: `
      .td{
          height: 50px;
          background-color: ${historyBgColor};
          color: ${historyFontColor};
      }
      .td > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const handleExportXLSX = () => {
    const exportData = data.nodes.map((d) => ({
      id: d.id.toString(),
      name: d.name,
      isActive: d.isActive ? "Yes" : "No",
      createdAt: d.createdAt,
      createdBy: d.createdBy,
      updatedAt: d.updatedAt,
      updatedBy: d.updatedBy,
    }));
    const workSheet = utils.json_to_sheet(exportData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "data");
    writeFile(workBook, "companies.xlsx", { compression: true });
  };

  const data = { nodes };
  const pagination = usePagination(data, {
    state: { size: 50 }
  });

  const openAddCompanyModal = () => {
    setOpenAddCompany.on();
    setCompanyModalContent(
      <CompanyModalContent openModal={setOpenAddCompany} />
    );
  };

  const openEditCompanyModal = (
    companyId: number,
    name: string,
    logoImagePath?: string
  ) => {
    setOpenAddCompany.on();
    setCompanyModalContent(
      <CompanyModalContent
        openModal={setOpenAddCompany}
        editMode={true}
        defaultValue={name}
        companyId={companyId}
        logoImagePath={logoImagePath}
      />
    );
  };

  return (
    <>
      <Modal isOpen={openAddCompany} onClose={setOpenAddCompany.off}>
        {companyModalContent}
      </Modal>
      <Flex w={"100%"} gap={"10px"} flexDirection={"column"}>
        <Box
          px={"1%"}
          py={"15px"}
          h={"70px"}
        >
          <HStack justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={() => openAddCompanyModal()}>
              Create
            </Button>
            <ConfirmPopover
              placement={"left-end"}
              confirmText={"Are you sure to download table companies.xlsx?"}
              onConfirm={() => handleExportXLSX()}
              triggerButton={<Button colorScheme={"green"}>Download</Button>}
            />
          </HStack>
        </Box>
        <Flex px={"1%"} flexDirection={"column"} h={"calc(100vh - 150px)"}>
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, fixedHeader: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Is Active</HeaderCell>
                    <HeaderCell>Created At</HeaderCell>
                    <HeaderCell>Created By</HeaderCell>
                    <HeaderCell>Updated At</HeaderCell>
                    <HeaderCell>Updated By</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={_.toString(item.id)}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.name}</Cell>
                      <Cell>{item.isActive ? "Yes" : "No"}</Cell>
                      <Cell>{moment(item.createdAt).format(DateFormat)}</Cell>
                      <Cell>{item.createdBy}</Cell>
                      <Cell>{moment(item.updatedAt).format(DateFormat)}</Cell>
                      <Cell>{item.updatedBy}</Cell>
                      <Cell>
                        <Flex gap={"10px"}>
                          <EditIcon
                            onClick={() =>
                              openEditCompanyModal(
                                parseInt(item.id),
                                item.name,
                                item.logoImagePath
                              )
                            }
                            cursor="pointer"
                            color={"blue.400"}
                          />
                        </Flex>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </Flex>
      </Flex>
    </>
  );
}
