import { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue
} from "@chakra-ui/react";
import { useChangePasswordMutation } from "../features/users/api/UserApiSlice";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../utils/ToastUtil";

const ChangePasswordPage = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [changePassword] = useChangePasswordMutation();
  const navigate = useNavigate();
  
  const submit = () => {
    if (!password) setIsPassword(false);
    else setIsPassword(true);

    if (!confirmPassword) setIsConfirmPassword(false);
    else setIsConfirmPassword(true);

    if (password !== confirmPassword) {
      errorToast("Password and Confirm Password do not match");
      return;
    }

    changePassword({ password: password }).then((res) => {
      if ("error" in res) {
        return;
      }

      successToast("Change Password Success");
      
      setPassword("");
      setConfirmPassword("");
    });
  };

  return (
    <Flex h={"95vh"} alignItems={"center"}>
      <Flex
        zIndex={99}
        flexDirection={"column"}
        alignItems={"center"}
        bg={bgColor}
        w={"30%"}
        minH={"300px"}
        h={"60%"}
        position={"relative"}
        left={"35%"}
        borderRadius={"5%"}
        boxShadow={
          "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
        }
      >
        <Flex
          mt={"20%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FormControl>
            <FormControl ms="4px" mb="24px" isInvalid={!isPassword}>
              <FormLabel fontSize="sm" fontWeight="normal">
                New Password
              </FormLabel>
              <Input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder="Password"
                size="lg"
              />
              <FormErrorMessage>Password is required.</FormErrorMessage>
            </FormControl>
            <FormControl ms="4px" mb="24px" isInvalid={!isConfirmPassword}>
              <FormLabel fontSize="sm" fontWeight="normal">
                Confirm Password
              </FormLabel>
              <Input
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="password"
                placeholder="Password"
                size="lg"
              />
              <FormErrorMessage>Confirm Password is required.</FormErrorMessage>
            </FormControl>
            <Button
              fontSize="sm"
              colorScheme="blue"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={submit}
            >
              Update
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChangePasswordPage;
