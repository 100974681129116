import { apiSlice } from "../../../services/ApiSlice";
import { GiftingResponse } from "../../../types/GiftingResponse";
import { TokenDto } from "./dto/AuthDto";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<TokenDto, { email: string, password: string }>({
      query: request => ({
        url: "/authentication/login",
        method: "POST",
        body: {email: request.email, password: request.password}
      })
    }),
    logout: builder.mutation<GiftingResponse, void>({
      query: () => ({
        url: "authentication/logout",
        method: "POST"
      })
    })
  })
});

export const {
  useLoginMutation,
  useLogoutMutation
} = authApiSlice;
