import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import _ from "lodash";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";
import { EditIcon } from "@chakra-ui/icons";
import {
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Table,
} from "@table-library/react-table-library";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { utils, writeFile } from "xlsx";
import { useGetUsersByCompanyIdQuery } from "../../features/users/api/UserApiSlice";
import UserModalAddContent from "../../features/users/components/modal/UserModalAddContent";
import { getUserInfoState } from "../../features/authentication/AuthSlice";
import { useAppSelector } from "../../components/common/Hooks";

export default function UserListPage() {
  const userInfoState = useAppSelector(getUserInfoState);
  const { data: apiData } = useGetUsersByCompanyIdQuery(userInfoState.userInfo.companyId);
  const [openAddUser, setOpenAddUser] = useBoolean();
  const [userModalContent, setUserModalContent] = useState<JSX.Element>();

  const historyBgColor = useColorModeValue("white", "#2D3748");
  const historyFontColor = useColorModeValue("#4a5568", "#f5f5f5");
  const headerBgColor = useColorModeValue("#E2E8F0", "#4A5568");

  const nodes = apiData?.success
    ? apiData?.users.map((user) => {
        return {
          id: user.userId.toString(),
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          companyId: user.companyId,
          companyName: user.companyName,
          isAdmin: user.isAdmin,
          isActive: user.isActive,
          createdAt: user.createdAt,
          createdBy: user.createdBy,
          updatedAt: user.updatedAt,
          updatedBy: user.updatedBy
        };
      })
    : [];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: 80px repeat(4, 200px) 100px 100px repeat(4, 200px) auto;
      grid-template-rows: 70px repeat(12, 50px);
      grid-auto-rows: repeat(12, 50px);
      border-width: 1px;
      overflow-y: auto;
    `,
    HeaderCell: `
      padding: 12px 20px;
    `,
    Header: `
      .th{
          height: 70px;
          border-bottom: 1px solid black;
          background-color: ${headerBgColor};
      }
    `,
    HeaderRow: `
      height: 70px;
      background-color: ${historyBgColor};
      color: ${historyFontColor};
    `,
    Row: `
      .td{
          height: 50px;
          background-color: ${historyBgColor};
          color: ${historyFontColor};
      }
      .td > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const handleExportXLSX = () => {
    const exportData = data.nodes.map((d) => ({
      id: d.id.toString(),
      companyId: d.companyId,
      companyName: d.companyName,
      email: d.email,
      firstName: d.firstName,
      lastName: d.lastName,
      isAdmin: d.isAdmin ? "Yes" : "No",
      isActive: d.isActive ? "Yes" : "No",
      createdAt: d.createdAt,
      createdBy: d.createdBy,
      updatedAt: d.updatedAt,
      updatedBy: d.updatedBy,
    }));
    const workSheet = utils.json_to_sheet(exportData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "data");
    writeFile(workBook, "users.xlsx", { compression: true });
  };

  const data = { nodes };
  const pagination = usePagination(data, {
    state: { size: 50 }
  });

  const openAddUserModal = () => {
    setOpenAddUser.on();
    setUserModalContent(
      <UserModalAddContent openModal={setOpenAddUser} />
    );
  };

  return (
    <>
      <Modal size={"xl"} isOpen={openAddUser} onClose={setOpenAddUser.off}>
        {userModalContent}
      </Modal>
      <Flex w={"100%"} gap={"10px"} flexDirection={"column"}>
        <Box
          px={"1%"}
          py={"15px"}
          h={"70px"}
        >
          <HStack justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={() => openAddUserModal()}>
              Create
            </Button>
            <ConfirmPopover
              placement={"left-end"}
              confirmText={"Are you sure to download table users.xlsx?"}
              onConfirm={() => handleExportXLSX()}
              triggerButton={<Button colorScheme={"green"}>Download</Button>}
            />
          </HStack>
        </Box>
        <Flex px={"1%"} flexDirection={"column"} h={"calc(100vh - 150px)"}>
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, fixedHeader: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Company</HeaderCell>
                    <HeaderCell>Email</HeaderCell>
                    <HeaderCell>First Name</HeaderCell>
                    <HeaderCell>Last Name</HeaderCell>
                    <HeaderCell>Is Admin</HeaderCell>
                    <HeaderCell>Is Active</HeaderCell>
                    <HeaderCell>Created At</HeaderCell>
                    <HeaderCell>Created By</HeaderCell>
                    <HeaderCell>Updated At</HeaderCell>
                    <HeaderCell>Updated By</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={_.toString(item.id)}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.companyName}</Cell>
                      <Cell>{item.email}</Cell>
                      <Cell>{item.firstName}</Cell>
                      <Cell>{item.lastName}</Cell>
                      <Cell>{item.isAdmin ? "Yes" : "No"}</Cell>
                      <Cell>{item.isActive ? "Yes" : "No"}</Cell>
                      <Cell>{moment(item.createdAt).format(DateFormat)}</Cell>
                      <Cell>{item.createdBy}</Cell>
                      <Cell>{moment(item.updatedAt).format(DateFormat)}</Cell>
                      <Cell>{item.updatedBy}</Cell>
                      <Cell>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </Flex>
      </Flex>
    </>
  );
}
