import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { CompaniesDto, CompanyWithClientListResponse } from "./api/dto/CompanyDto";

type CompanyState = {
  companies: CompaniesDto[]
  companiesWithClients: CompanyWithClientListResponse[] 
}

const initialState: CompanyState = {
  companies: [],
  companiesWithClients: []
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
});

export const {
  reset,
} = companySlice.actions;

export default companySlice.reducer;
