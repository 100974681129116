import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../../components/common/Hooks";
import { GiftSelection, GiftSubCategorySelection } from "../../features/gift-recipients/api/dto/GiftRecipientDto";
import {
  getGiftRecipientState,
  setSelectedGiftId,
  setSelectedGiftSubCategoryId,
} from "../../features/gift-recipients/GiftRecipientSlice";
import { setAddNextClickCount } from "../../features/step-progress/StepProgressSlice";

function ClientGiftSelectionPage() {
  const dispatch = useAppDispatch();
  const giftRecipientState = useAppSelector(getGiftRecipientState);
  const [selectedGiftSubCategory, setSelectedGiftSubCategory] = useState<GiftSubCategorySelection[] | undefined>();
  const [gift, setGift] = useState<GiftSelection>();

  useEffect(() => {
    dispatch(
      setSelectedGiftSubCategoryId(giftRecipientState.giftRecipient!.giftSubCategories[0].giftSubCategoryId)
    );

    let selectedGiftSubCategory =
      giftRecipientState.giftRecipient?.giftSubCategories.filter(
        (item) =>
          item.giftSubCategoryId === giftRecipientState.giftRecipient?.giftSubCategories[0].giftSubCategoryId
      );

    setSelectedGiftSubCategory(selectedGiftSubCategory);
  }, []);

  function handleGiftSubCategoryOnClick(giftSubCategoryId: number) {
    dispatch(setSelectedGiftSubCategoryId(giftSubCategoryId));

    setSelectedGiftSubCategory(giftRecipientState.giftRecipient?.giftSubCategories.filter(
      (item) => item.giftSubCategoryId === giftSubCategoryId
    ));
  }

  function handleGiftOnClick(giftId: number) {
    const selectedGift = selectedGiftSubCategory![0].gifts.filter(
      (item) => item.giftId == giftId
    )[0];
    setGift(selectedGift);

    dispatch(setSelectedGiftId(giftId));
    dispatch(setAddNextClickCount());
  }

  return (
    <Center>
      <Flex flexDirection={"column"}>
        <Grid w={"100%"} maxW={{ base: "360px", sm: "768px", md: "992px", lg: "1280px", xl: "1536px" }} templateColumns={"repeat(6, 1fr)"} gap={5}>
          <GridItem colSpan={6} mb={"20px"}>
            <Center fontSize={24} fontWeight={"semibold"}>
              Choose a category, then a gift!
            </Center>
          </GridItem>
          {giftRecipientState.giftRecipient?.giftSubCategories.map((item) => {
            return (
              <GridItem
                key={item.giftSubCategoryId}
                w="100%"
                minH={"130px"}
                maxH={"200px"}
                bg="white"
                rounded="lg"
                boxShadow={"lg"}
                border={
                  item.giftSubCategoryId ===
                  giftRecipientState.selectedGiftSubCategoryId
                    ? "2px solid #EE562A"
                    : ""
                }
                p={"15px"}
                onClick={() =>
                  handleGiftSubCategoryOnClick(item.giftSubCategoryId)
                }
              >
                <Stack direction="column">
                  <Image
                    src={item.imagePath}
                    alt="Image not found"
                    w={{ sm: "220px", md: "220px" }}
                    h={"80px"}
                    objectFit="cover"
                    borderRadius="10px"
                  ></Image>
                  <Text
                    align={"center"}
                    textTransform={"uppercase"}
                    color={
                      item.giftSubCategoryId ===
                      giftRecipientState.selectedGiftSubCategoryId
                        ? "#EE562A"
                        : ""
                    }
                  >
                    {item.name}
                  </Text>
                </Stack>
              </GridItem>
            );
          })}
        </Grid>
        <Grid w={"100%"} templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={5}>
          <GridItem colSpan={{ base: 2, sm: 2, md: 3 }} mb={"20px"}>
          </GridItem>
          {selectedGiftSubCategory !== undefined &&
          selectedGiftSubCategory.length > 0
            ? selectedGiftSubCategory[0].gifts.map((item) => {
                return (
                  <GridItem
                    key={item.giftId}
                    w="100%"
                    h="230px"
                    bg="white"
                    rounded="lg"
                    boxShadow={"lg"}
                    border={
                      item.giftId === giftRecipientState.selectedGiftId
                        ? "2px solid #EE562A"
                        : ""
                    }
                    onClick={() => handleGiftOnClick(item.giftId)}
                  >
                    <Stack direction="column">
                      <Image
                        src={item.imagePath}
                        alt="Image not found"
                        w={"100%"}
                        h={"150px"}
                        objectFit={"cover"}
                        borderTopRadius="10px"
                      ></Image>
                      <Text pl={"15px"}>{item.name}</Text>
                      <HStack>
                        <Text pl={"15px"} color={"#EE562A"}>
                          View more
                        </Text>
                        <Icon
                          as={HiArrowRight}
                          fontSize={"xl"}
                          color={"#EE562A"}
                          pt={1}
                        ></Icon>
                      </HStack>
                    </Stack>
                  </GridItem>
                );
              })
            : null}
        </Grid>
      </Flex>
    </Center>
  );
}

export default ClientGiftSelectionPage;
