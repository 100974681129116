import { ChangeEvent, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { useCreateGiftCategoryMutation, useUpdateGiftCategoryMutation } from "../../api/GiftApiSlice";
import { FileUploadModel } from "../../../../types/FileUploadModel";
import { convertToBase64 } from "../../../../utils/FileUploadUtil";
import { successToast } from "../../../../utils/ToastUtil";

type GiftCategoryModalContentProps = {
  editMode?: boolean;
  defaultValue?: string;
  giftCategoryId?: number;
  imagePath?: string;
  openModal: { on: () => void; off: () => void; toggle: () => void };
  changeDisplayId?: (id: number) => void;
};

const GiftCategoryModalContent = (props: GiftCategoryModalContentProps) => {
  const {
    editMode = false,
    defaultValue,
    giftCategoryId,
    imagePath,
    openModal
  } = props;
  const [name, setName] = useState<string>(defaultValue || "");
  const [isError, setIsError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [createGiftCategory, { isLoading: submitLoading }] = useCreateGiftCategoryMutation();
  const [updateGiftCategory, { isLoading: updateLoading }] = useUpdateGiftCategoryMutation();

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e != null && e.target.files != null && e.target.files?.length > 0) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const submitGiftCategory = async () => {
    if (name === "") {
      setIsError(true);
      return;
    }

    let fileUploadModel: FileUploadModel = { fileName: '', base64String: '' };
    if (uploadedFile != null) {
      fileUploadModel.fileName = uploadedFile.name;
      fileUploadModel.base64String = await convertToBase64(uploadedFile) as string;
      if (fileUploadModel.base64String) {
        fileUploadModel.base64String = fileUploadModel.base64String.split(';base64,')[1]
      }
    }

    let result;
    if (editMode) {
      result = await updateGiftCategory({
        giftCategoryId: giftCategoryId || 0,
        name,
        file: fileUploadModel
      });
    } else {
      result = await createGiftCategory({
        name, file: fileUploadModel
      });
    }

    if ("error" in result || !result.data.success) {
      return;
    }

    successToast(`Gift Category ${editMode ? "Update" : "Add"} Success!`);
    
    openModal.off();
  };

  return (
    <Box>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editMode ? "Edit Category" : "Add Category"}</ModalHeader>
        <Divider />
        <ModalBody>
          <FormControl isRequired isInvalid={isError && name === ""}>
            <FormLabel>Category Name</FormLabel>
            <Input
              placeholder={"Category Name"}
              value={name}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "") {
                  setIsError(true);
                }
                setName(e.target.value);
              }}
            />
            {isError && name === "" && (
              <FormErrorMessage>Category Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mt="4" hidden={!editMode || !imagePath}>
            <FormLabel>Current Image</FormLabel>
            <Avatar size={"2xl"} name="Image" src={imagePath}></Avatar>
          </FormControl>
          <FormControl mt="4">
            <FormLabel>{editMode && imagePath ? "Upload New Image" : "Upload Image"}</FormLabel>
            <Input 
              type="file"
              onChange={saveFile}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={submitGiftCategory}
            colorScheme="blue"
            isLoading={editMode ? updateLoading : submitLoading}
            mr="3"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={() => openModal.off()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Box>
  );
};

export default GiftCategoryModalContent;
