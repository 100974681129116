import { useReducer, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCreateClientRecipientMutation } from "../api/ClientApiSlice";
import { successToast } from "../../../utils/ToastUtil";
import { ClientRecipientInfoAction } from "../api/redux/ClientRecipientInfoAction";
import { ClientRecipientCreateRequest } from "../api/dto/ClientDto";
import { SingaporeCountryId } from "../../../constants/ValueConstant";
import { useGetCountriesQuery } from "../../countries/api/CountryApiSlice";

type ClientRecipientAddContentProps = {
  setAddOpen: { on: () => void; off: () => void; toggle: () => void };
  companyId?: number;
  clientId?: number;
};

const ClientRecipientAddContent = (props: ClientRecipientAddContentProps) => {
  const { companyId, clientId } = props;
  const { data: countriesApiData } = useGetCountriesQuery();
  const [submit, { isLoading: submitLoading, isError: addError }] =
    useCreateClientRecipientMutation();
  const [isError, setIsError] = useState(false);

  const initialValue: ClientRecipientCreateRequest = {
    companyId: companyId || 0,
    clientId: clientId || 0,
    email: "",
    firstName: "",
    lastName: undefined,
    dob: undefined,
    genderId: 0,
    occupation: undefined,
    countryId: SingaporeCountryId,
    phoneNumber:  undefined,
    addressLineOne: "",
    addressLineTwo: undefined,
    postalCode: ""
  };

  const clientRecipientReducer = (
    state: ClientRecipientCreateRequest,
    action: ClientRecipientInfoAction
  ) => {
    const { payload } = action;

    return { ...state, [action.type]: payload };
  };

  const handleSubmit = async () => {
    if (
      isError ||
      state.email.trim() === "" ||
      state.firstName.trim() === ""
    ) {
      setIsError(true);
      return;
    }

    const result = await submit(state);
    if ("error" in result) {
      return;
    }

    if (!addError) {
      successToast('Success');
    }
    props.setAddOpen.off();
  };

  const [state, dispatch] = useReducer(clientRecipientReducer, initialValue);

  return (
    <ModalContent top={"0%"} maxW={"unset"} w={"800px"}>
      <ModalHeader>Add Recipient</ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody px={"40px"}>
        <Grid
          rowGap={"10px"}
          columnGap={"10%"}
          templateColumns={"repeat(2, 1fr)"}
        >
          <FormControl
            isRequired
            isInvalid={isError && state.email.trim() === ""}
          >
            <FormLabel>Email</FormLabel>
            <Input
              value={state.email}
              placeholder={"Email"}
              onChange={(e) => {
                if (state.email.trim() !== "") {
                  setIsError(false);
                }
                dispatch({ type: "email", payload: e.target.value });
              }}
            ></Input>
            {isError && state.firstName.trim() === "" && (
              <FormErrorMessage>Email is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isError && state.countryId === 0}>
            <FormLabel>Country</FormLabel>
            <Select
              onChange={(e) => {
                if (state.countryId !== 0) {
                  setIsError(false);
                }
                dispatch({ type: "countryId", payload: parseInt(e.target.value) });
              }}
              value={state.countryId}
            >
              <option key={0} value={0}>
                Please select
              </option>
              {countriesApiData?.success
                ? countriesApiData?.countries.map((country) => (
                    <option key={country.countryId} value={country.countryId}>
                      {country.name + ' (+' + country.dialCode + ')' }
                    </option>
                  ))
                : ""}
            </Select>
            {isError && state.countryId === 0 && (
              <FormErrorMessage>Country is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && state.firstName.trim() === ""}
          >
            <FormLabel>First Name</FormLabel>
            <Input
              value={state.firstName}
              placeholder={"First Name"}
              onChange={(e) => {
                if (state.firstName.trim() !== "") {
                  setIsError(false);
                }
                dispatch({ type: "firstName", payload: e.target.value });
              }}
            ></Input>
            {isError && state.firstName.trim() === "" && (
              <FormErrorMessage>First Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              value={state.lastName}
              placeholder={"Last Name"}
              onChange={(e) => {
                dispatch({ type: "lastName", payload: e.target.value });
              }}
            ></Input>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && state.genderId === 0}
          >
            <FormLabel>Gender</FormLabel>
            <Select
              onChange={(e) => {
                if (state.genderId !== 0) {
                  setIsError(false);
                }
                dispatch({ type: "genderId", payload: parseInt(e.target.value) });
              }}
              value={state.genderId}
            >
              <option key={0} value={0}>
                Please select
              </option>
              <option key={1} value={1}>
                Male
              </option>
              <option key={2} value={2}>
                Female
              </option>
            </Select>
            {isError && state.genderId === 0 && (
              <FormErrorMessage>Gender is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Occupation</FormLabel>
            <Input
              value={state.occupation}
              placeholder={"Occupation"}
              onChange={(e) => {
                dispatch({ type: "occupation", payload: e.target.value });
              }}
            ></Input>
          </FormControl>
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Input
              value={state.phoneNumber}
              placeholder={"Phone Number"}
              onChange={(e) => {
                dispatch({ type: "phoneNumber", payload: e.target.value });
              }}
            ></Input>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && state.addressLineOne.trim() === ""}
          >
            <FormLabel>Address Line 1</FormLabel>
            <Input
              value={state.addressLineOne}
              placeholder={"Address Line 1"}
              onChange={(e) => {
                if (state.addressLineOne.trim() !== "") {
                  setIsError(false);
                }
                dispatch({ type: "addressLineOne", payload: e.target.value });
              }}
            ></Input>
            {isError && state.addressLineOne.trim() === "" && (
              <FormErrorMessage>Address Line 1 is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Address Line 2</FormLabel>
            <Input
              value={state.addressLineTwo}
              placeholder={"Address Line 2"}
              onChange={(e) => {
                dispatch({ type: "addressLineTwo", payload: e.target.value });
              }}
            ></Input>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={isError && state.postalCode.trim() === ""}
          >
          <FormLabel>Postal Code</FormLabel>
          <Input
            value={state.postalCode}
            placeholder={"Postal Code"}
            onChange={(e) => {
              if (state.postalCode.trim() !== "") {
                setIsError(false);
              }
              dispatch({ type: "postalCode", payload: e.target.value });
            }}
          ></Input>
          {isError && state.firstName.trim() === "" && (
            <FormErrorMessage>Postal Code is required.</FormErrorMessage>
          )}
        </FormControl>
        </Grid>
      </ModalBody>
      <ModalFooter justifyContent={"center"} gap={"10%"} mt={"10px"}>
        <Button
          colorScheme="blue"
          onClick={_.debounce(handleSubmit, 200)}
          isLoading={submitLoading}
        >
          Save
        </Button>
        <Button onClick={props.setAddOpen.off}>Close</Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default ClientRecipientAddContent;
