import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  useBoolean,
  useColorModeValue
} from "@chakra-ui/react";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import _ from "lodash";
import moment from "moment";
import { DateFormat } from "../../constants/DateConstant";
import { EditIcon } from "@chakra-ui/icons";
import {
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Table,
} from "@table-library/react-table-library";
import ConfirmPopover from "../../components/common/popover/ConfirmPopover";
import { utils, writeFile } from "xlsx";
import { CampaignRecipientType } from "../../features/campaigns/api/dto/CampaignDto";
import { GiftTrackingStatus } from "../../features/gifts/api/dto/GiftDto";
import GiftRecipientModalContent from "../../features/gift-recipients/components/modal/GiftRecipientModalContent";
import { getUserInfoState } from "../../features/authentication/AuthSlice";
import { useAppSelector } from "../../components/common/Hooks";
import { useGetGiftRecipientsByCompanyIdQuery } from "../../features/gift-recipients/api/GiftRecipientApiSlice";

export default function GiftRecipientListPage() {
  const userInfoState = useAppSelector(getUserInfoState);
  const { data: apiData } = useGetGiftRecipientsByCompanyIdQuery(userInfoState.userInfo.companyId);
  const [openEditGiftRecipient, setOpenEditGiftRecipient] = useBoolean();
  const [giftRecipientModalContent, setGiftRecipientModalContent] = useState<JSX.Element>();

  const historyBgColor = useColorModeValue("white", "#2D3748");
  const historyFontColor = useColorModeValue("#4a5568", "#f5f5f5");
  const headerBgColor = useColorModeValue("#E2E8F0", "#4A5568");

  const nodes = apiData?.success
    ? apiData?.giftRecipients.map((recipient) => {
        return {
          id: recipient.giftRecipientId.toString(),
          giftId: recipient.giftId,
          status: recipient.status,
          message: recipient.message,
          recipientType: recipient.recipientType,
          email: recipient.email,
          isActive: recipient.isActive,
          createdAt: recipient.createdAt,
          createdBy: recipient.createdBy,
          updatedAt: recipient.updatedAt,
          updatedBy: recipient.updatedBy
        };
      })
    : [];

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: 80px 100px 150px 150px 200px 100px repeat(4, 200px) auto;
      grid-template-rows: 70px repeat(11, 50px);
      grid-auto-rows: repeat(11, 50px);
      border-width: 1px;
      overflow-y: auto;
    `,
    HeaderCell: `
      padding: 12px 20px;
    `,
    Header: `
      .th{
          height: 70px;
          border-bottom: 1px solid black;
          background-color: ${headerBgColor};
      }
    `,
    HeaderRow: `
      height: 70px;
      background-color: ${historyBgColor};
      color: ${historyFontColor};
    `,
    Row: `
      .td{
          height: 50px;
          background-color: ${historyBgColor};
          color: ${historyFontColor};
      }
      .td > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
    `,
  };
  const theme = useTheme([chakraTheme, customTheme]);

  const handleExportXLSX = () => {
    const exportData = data.nodes.map((d) => ({
      id: d.id.toString(),
      giftId: d.giftId,
      status: GiftTrackingStatus[d.status],
      campaignTypeId: CampaignRecipientType[d.recipientType],
      email: d.email,
      isActive: d.isActive ? "Yes" : "No",
      createdAt: d.createdAt,
      createdBy: d.createdBy,
      updatedAt: d.updatedAt,
      updatedBy: d.updatedBy,
    }));
    const workSheet = utils.json_to_sheet(exportData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "data");
    writeFile(workBook, "gift-recipients.xlsx", { compression: true });
  };

  const data = { nodes };
  const pagination = usePagination(data, {
    state: { size: 50 }
  });

  const openEditGiftRecipientModal = (
    giftRecipientId: number,
    message?: string
  ) => {
    setOpenEditGiftRecipient.on();
    setGiftRecipientModalContent(
      <GiftRecipientModalContent
        openModal={setOpenEditGiftRecipient}
        editMode={true}
        giftRecipientId={giftRecipientId}
        message={message}
      />
    );
  };

  return (
    <>
      <Modal size={"xl"} isOpen={openEditGiftRecipient} onClose={setOpenEditGiftRecipient.off}>
        {giftRecipientModalContent}
      </Modal>
      <Flex w={"100%"} gap={"10px"} flexDirection={"column"}>
        <Box
          px={"1%"}
          py={"15px"}
          h={"70px"}
        >
          <HStack justifyContent={"flex-end"}>
            <ConfirmPopover
              placement={"left-end"}
              confirmText={"Are you sure to download table gift-recipients.xlsx?"}
              onConfirm={() => handleExportXLSX()}
              triggerButton={<Button colorScheme={"green"}>Download</Button>}
            />
          </HStack>
        </Box>
        <Flex px={"1%"} flexDirection={"column"} h={"calc(100vh - 150px)"}>
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, fixedHeader: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Gift ID</HeaderCell>
                    <HeaderCell>Type</HeaderCell>
                    <HeaderCell>Status</HeaderCell>
                    <HeaderCell>Email</HeaderCell>
                    <HeaderCell>Is Active</HeaderCell>
                    <HeaderCell>Created At</HeaderCell>
                    <HeaderCell>Created By</HeaderCell>
                    <HeaderCell>Updated At</HeaderCell>
                    <HeaderCell>Updated By</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={_.toString(item.id)}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.giftId}</Cell>
                      <Cell>{CampaignRecipientType[item.recipientType]}</Cell>
                      <Cell>{GiftTrackingStatus[item.status]}</Cell>
                      <Cell>{item.email}</Cell>
                      <Cell>{item.isActive ? "Yes" : "No"}</Cell>
                      <Cell>{moment(item.createdAt).format(DateFormat)}</Cell>
                      <Cell>{item.createdBy}</Cell>
                      <Cell>{moment(item.updatedAt).format(DateFormat)}</Cell>
                      <Cell>{item.updatedBy}</Cell>
                      <Cell>
                        <Flex gap={"10px"}>
                          <EditIcon
                            onClick={() =>
                              openEditGiftRecipientModal(
                                parseInt(item.id),
                                item.message
                              )
                            }
                            cursor="pointer"
                            color={"blue.400"}
                          />
                        </Flex>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </Flex>
      </Flex>
    </>
  );
}
