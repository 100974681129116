import { GiftingResponse } from "../../../../types/GiftingResponse";

export interface UsersDto extends GiftingResponse {
  users: UserDto[];
}

export type UserDto = {
  userId: number;
  email: string;
  firstName: string;
  lastName?: string;
  companyId: number;
  companyName: string;
  profileImagePath?: string;
  isAdmin: boolean;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface UserCreateRequest {
  email: string;
  password: string;
  firstName: string;
  lastName?: string;
  companyId: number;
  userRoleId: number;
}

export interface UserCreateResponse extends GiftingResponse {
  userId: number;
}

export interface UserUpdateRequest {
  userId: number;
  firstName: string;
  lastName?: string;
}

export enum UserRoleEnum {
  SuperAdmin = 1,
  ClientAdmin = 2,
  ClientUser = 3
}